import { Box, Flex } from '@cfacorp/cowponents';
import PropTypes from 'prop-types';
import { isEmpty } from 'ramda';
import { Field, reduxForm } from 'redux-form';

import constants from '../../constants';
import normalizePhone from '../../util/normalizePhone';
import { stripNonPOSSpecialCharacters } from '../../util/utils';
import { validateSecondaryContact } from '../../util/validate';
import ValidatedField from './ValidatedField';

export const SecondaryContactField = ({ addSecondaryContact, secondaryContactValues }) => {
  const { firstName, lastName, phoneNumber } = secondaryContactValues;
  const handleAdd = () => {
    if (
      !isEmpty(secondaryContactValues) &&
      firstName !== '' &&
      firstName !== undefined &&
      lastName !== '' &&
      lastName !== undefined &&
      phoneNumber !== '' &&
      phoneNumber !== undefined
    ) {
      addSecondaryContact();
    }
  };
  return (
    <Box>
      <Flex flexWrap="wrap">
        <Box width={[1, 1 / 3]}>
          <Field
            className="first-name"
            component={ValidatedField}
            name="firstName"
            normalize={stripNonPOSSpecialCharacters}
            onBlur={handleAdd}
            placeholder="First Name"
            requiredWithoutTouch
            type="text"
          />
        </Box>
        <Box width={[1, 1 / 3]}>
          <Field
            className="last-name"
            component={ValidatedField}
            name="lastName"
            normalize={stripNonPOSSpecialCharacters}
            onBlur={handleAdd}
            placeholder="Last Name"
            requiredWithoutTouch
            type="text"
          />
        </Box>
        <Box width={[1, 1 / 3]}>
          <Field
            className="phone-number"
            component={ValidatedField}
            name="phoneNumber"
            normalize={normalizePhone}
            onBlur={handleAdd}
            placeholder="Phone Number"
            requiredWithoutTouch
            type="text"
          />
        </Box>
      </Flex>
    </Box>
  );
};

SecondaryContactField.propTypes = {
  addSecondaryContact: PropTypes.func,
  secondaryContactValues: PropTypes.objectOf(PropTypes.string),
};

SecondaryContactField.defaultProps = {
  addSecondaryContact: () => {},
  secondaryContactValues: {},
};

export default reduxForm({
  form: constants.GET_FORM_TYPES.SECONDARY_CONTACT,
  destroyOnUnmount: false,
  validate: validateSecondaryContact,
  initialValues: {},
})(SecondaryContactField);
