import { Action, Store } from '@reduxjs/toolkit';

class ReduxStore {
  static savedStore?: Store;

  static get state() {
    return this.savedStore?.getState?.() || {};
  }

  static dispatch(action: Action) {
    return this.savedStore?.dispatch?.(action) || {};
  }

  static set store(newStore: Store) {
    this.savedStore = newStore;
  }
}

export default ReduxStore;
