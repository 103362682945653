import type { Nullable } from '../types/util';

const normalizePhone = (value?: Nullable<string>) => {
  if (!value) {
    return value;
  }
  let countryCodeUsed = false;
  if (value[0] === '+') {
    countryCodeUsed = true;
  }
  const onlyNums = value.replace(/[^\d]/g, '');
  if (!countryCodeUsed) {
    if (onlyNums.length <= 3) {
      return onlyNums;
    }
    if (onlyNums.length <= 7) {
      return `${onlyNums.slice(0, 3)} ${onlyNums.slice(3)}`;
    }
    return `${onlyNums.slice(0, 3)} ${onlyNums.slice(3, 6)} ${onlyNums.slice(6, 10)}`;
  }

  // handle special case where a + is used but no country code, i.e. for +6781234567 we assume US/Canada
  if (countryCodeUsed && onlyNums.length === 10) {
    return `+1 ${onlyNums.slice(0, 3)} ${onlyNums.slice(3, 6)} ${onlyNums.slice(6)}`;
  }

  const firstSplitIndex = onlyNums.length > 11 ? 2 : 1;
  if (onlyNums.length <= firstSplitIndex) {
    return `+${onlyNums}`;
  }
  const secondSplitIndex = firstSplitIndex + 3;
  if (onlyNums.length <= secondSplitIndex) {
    return `+${onlyNums.slice(0, firstSplitIndex)} ${onlyNums.slice(firstSplitIndex)}`;
  }
  const thirdSplitIndex = secondSplitIndex + 3;
  if (onlyNums.length <= thirdSplitIndex) {
    return `+${onlyNums.slice(0, firstSplitIndex)} ${onlyNums.slice(firstSplitIndex, secondSplitIndex)} ${onlyNums.slice(secondSplitIndex)}`;
  }
  const maxLength = Math.min(onlyNums.length, 12);
  return `+${onlyNums.slice(0, firstSplitIndex)} ${onlyNums.slice(firstSplitIndex, secondSplitIndex)} ${onlyNums.slice(secondSplitIndex, thirdSplitIndex)} ${onlyNums.slice(thirdSplitIndex, maxLength)}`;
};

export default normalizePhone;
