import { assoc, clone, contains, equals, indexBy, length, map, prop, propOr, reduce } from 'ramda';
import { v4 } from 'uuid';
import type { JSONObject } from '../types/util';
import type { OrderLineItem, OrderLineItemComboItem } from '../types/order';
import type { CartItemModifier } from '../types/cart';

import ooeConstants from '../constants';
import type { State as CartState } from '../reducers/cart';

const mapModifierToCartModifier = ({
  itemTag,
  quantity = 1,
  comboTag,
}: {
  itemTag: string;
  quantity?: number;
  comboTag?: string;
}) => ({
  quantity,
  comboTag,
  tag: itemTag,
});

function findSide(lineItem: OrderLineItem) {
  const { comboItems } = lineItem;
  if (comboItems) {
    return comboItems.find((item) => contains(item.itemTag, ooeConstants.SIDES));
  }
  return {};
}

function findDessert(lineItem: OrderLineItem) {
  const { comboItems } = lineItem;
  if (comboItems) {
    return comboItems.find((item) => contains(item.itemTag, ooeConstants.DESSERTS));
  }
  return {};
}

function getComboModifiers(lineItem: OrderLineItem) {
  const { comboItems } = lineItem;
  if (comboItems) {
    const comboModifiers = reduce(
      (acc, value) => {
        const { modifiers, itemTag } = value;
        if (Array.isArray(modifiers) && length(modifiers) > 0) {
          const taggedModifiers = map(assoc('comboTag', itemTag), modifiers);
          return [...acc, ...taggedModifiers];
        }
        return acc;
      },
      [] as OrderLineItemComboItem[],
      comboItems,
    );

    return comboModifiers;
  }
  return [];
}

function getAllModifiers(lineItem: OrderLineItem) {
  const itemModifiers = propOr<
    Array<{
      itemTag: string;
      quantity: number;
    }>,
    typeof lineItem,
    Array<{
      itemTag: string;
      quantity: number;
    }>
  >([], 'modifiers', lineItem);
  const comboModifiers = getComboModifiers(lineItem);
  const allModifiers = [...itemModifiers, ...comboModifiers];
  return map(mapModifierToCartModifier, allModifiers);
}

function getIndexedModifiers(lineItem: OrderLineItem): Record<string, CartItemModifier> {
  const allModifiers = getAllModifiers(lineItem);
  return indexBy(prop('tag'), allModifiers);
}

export function mapLineItemToCartItem(lineItem: OrderLineItem) {
  const { itemTag, promoFree } = lineItem;
  const baseCartItem = {
    tag: itemTag,
    quantity: propOr<number, typeof lineItem, number>(1, 'quantity', lineItem),
    specialInstructions: propOr<string, typeof lineItem, string>('', 'specialInstructions', lineItem),
    promoFree,
  };

  const modifiers = getIndexedModifiers(lineItem);
  const selectedSideTag = propOr<undefined, ReturnType<typeof findSide>, undefined>(
    undefined,
    'itemTag',
    findSide(lineItem),
  );
  const selectedDessertTag = propOr<undefined, ReturnType<typeof findDessert>, undefined>(
    undefined,
    'itemTag',
    findDessert(lineItem),
  );
  return {
    ...baseCartItem,
    selectedSideTag,
    selectedDessertTag,
    modifiers,
    id: v4(),
  };
}

export const convertLineItemsToCartItems = (lineItems: OrderLineItem[] = []): CartState => {
  return map((i) => mapLineItemToCartItem(i), lineItems);
};

export const consolidateItems = (items?: Array<JSONObject & { quantity: number }>) => {
  const dest: Array<{ quantity: number }> = [];

  if (items) {
    const src = clone(items);
    src.forEach((srcItem) => {
      let updatedQty = false;
      for (const destItem of dest) {
        if (
          equals(
            {
              ...destItem,
              id: 0,
              quantity: 1,
              key: 1,
              price: 1,
            },
            {
              ...srcItem,
              id: 0,
              quantity: 1,
              key: 1,
              price: 1,
            },
          )
        ) {
          destItem.quantity += srcItem.quantity;
          updatedQty = true;
          break;
        }
      }
      if (!updatedQty) {
        dest.push(srcItem);
      }
    });
  }
  return dest;
};
