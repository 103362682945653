import PropTypes from 'prop-types';
import styled from 'styled-components';

function Defer({ className }) {
  return <div className={className}>Use this for guest paying upon pickup and TBC (To Be Collected)</div>;
}

const StyledDefer = styled(Defer)`
  text-align: left;
  padding: 5px 41px;
  color: ${(props) => props.theme.colors.text};
  line-height: 1.4em;
  clear: both;
`;

Defer.propTypes = {
  className: PropTypes.string.isRequired,
};

export default StyledDefer;
