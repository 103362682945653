import { Lock } from '@cfa/system-icons';
import { Badge, Input } from '@cfacorp/cowponents';
import PropTypes from 'prop-types';
import { propOr } from 'ramda';
import { memo, useState } from 'react';
import styled from 'styled-components';

import { ensureValueIsInteger } from '../../util/validate';
import Icon from '../Icon';
import ItemWarningModal from '../Modal/ItemWarningModal';
import Tooltip from '../Tooltip/Tooltip';
import MenuItemPrice from './MenuItemPrice';
import Nutrition from './Nutrition';

const LockIcon = <Lock className="lockIcon" color="#DD0031" size="24px" />;

export const MenuItem = (props) => {
  const {
    className,
    item,
    quantity,
    hidePricing,
    hideQuantityInput,
    updateQuantity,
    toggleable,
    recipe,
    nutrition,
    addToCart,
  } = props;

  const {
    itemPrice,
    name,
    desktopImage,
    mobileImage,
    pdpImages,
    variation,
    leadTime: itemLeadTime,
    disabled,
  } = item;
  let modifierClassName = '';
  if (toggleable) {
    modifierClassName += 'toggleable';
  }
  if ((toggleable && quantity > 0) || (recipe && quantity === 0)) {
    modifierClassName += ' selected';
  }
  if (recipe && quantity > 0) {
    modifierClassName = 'toggleable';
  }

  const imgUrl = desktopImage || pdpImages || mobileImage;

  const [showWarningModal, setShowWarningModal] = useState(null);

  const [stepperValue, setStepperValue] = useState(quantity);

  const handleQuantityChange = (e) => {
    setStepperValue(e.target.value);
    if (e.target.value === '') {
      return;
    }

    const qty = ensureValueIsInteger(e.target.value);
    updateQuantity(qty);
  };

  const onAddToCart = () => {
    if (disabled) {
      return;
    }
    if (item.emergencyMessage) {
      setShowWarningModal(true);
    } else {
      addToCart(item);
    }
  };

  const onConfirm = () => {
    addToCart(item);
    setShowWarningModal(false);
  };

  const onCloseHandler = () => setShowWarningModal(false);

  return (
    <div className={className}>
      {showWarningModal && (
        <ItemWarningModal
          isOpen={showWarningModal}
          message={item.emergencyMessage}
          onClose={onCloseHandler}
          onConfirm={onConfirm}
        />
      )}
      <div className={modifierClassName} onClick={onAddToCart} onKeyDown={() => {}} role="presentation">
        <div className="image-wrapper">
          {toggleable ? (
            <img
              alt={name}
              className={disabled ? 'image disabled' : 'image'}
              data-cy={`${item.itemGroupType}-${item.tag}`}
              src={imgUrl}
            />
          ) : (
            <Badge
              badgeContent={quantity}
              className="badge"
              data-cy={`${item.itemGroupType}-${item.tag}-badge`}
            >
              <img
                alt={name}
                className={disabled ? 'image disabled' : 'image'}
                data-cy={`${item.itemGroupType}-${item.tag}`}
                src={imgUrl}
              />
            </Badge>
          )}
          {disabled && LockIcon}
        </div>
        <div className={disabled ? 'name-wrapper disabled' : 'name-wrapper'}>
          <div className="name">
            {name}
            <span className="info" onClick={(e) => e.stopPropagation()}>
              &nbsp;
              {nutrition.nutrition && (
                <Tooltip
                  className="info"
                  tooltipContent={
                    <Nutrition
                      nutrition={nutrition.nutrition}
                      servingSize={propOr('', 'servingSize', nutrition)}
                    />
                  }
                >
                  <Icon height="12" margin="0" width="12">
                    info
                  </Icon>
                </Tooltip>
              )}
            </span>
          </div>
          <MenuItemPrice hidePricing={hidePricing} itemPrice={itemPrice} variation={variation} />
        </div>
        {itemLeadTime && (
          <div className="leadTime-details">
            <Icon height="17" margin="0 2px 2px 0" width="17">
              warning
            </Icon>
            <div className="leadTime">{`Lead time: ${itemLeadTime}h`}</div>
          </div>
        )}
      </div>
      {hideQuantityInput || toggleable ? null : (
        <Input
          data-cy={`quantity-${item.tag}`}
          display="block"
          height="39px"
          m="5px auto 0"
          max="999"
          maxWidth="50px"
          onBlur={() => setStepperValue(quantity)}
          onChange={handleQuantityChange}
          pl="10px"
          textAlign="center"
          type="number"
          value={stepperValue}
          width="calc(100% - 35px)"
        />
      )}
    </div>
  );
};

const StyledMenuItem = styled(MenuItem)`
  flex: ${(props) => (props.imageSize === 'sm' ? '0 1 24.5%' : '0 1 33.33%')};
  height: 100%;
  box-sizing: border-box;
  color: ${(props) => props.theme.colors.primary};
  padding-bottom: 10px;
  position: relative;

  &:hover {
    cursor: pointer;
  }

  & .image-wrapper,
  .name-wrapper {
    margin: 0 auto;
    text-align: center;
  }

  & .name-wrapper {
    min-height: 30px;
    display: ${(props) => (props.imageSize === 'sm' ? 'flex' : 'block')};
    justify-content: center;
    &.disabled {
      color: #a7acaf;
    }
  }

  & .image-wrapper {
    position: relative;
    height: ${(props) => (props.imageSize === 'sm' ? '75px' : '100px')};
    width: ${(props) => (props.imageSize === 'sm' ? '75px' : '100px')};
  }

  & .lockIcon {
    position: absolute;
    float: right;
  }

  & .image {
    max-width: 100%;
    max-height: 100%;
    display: block;
    margin: 0 auto;
    &.disabled {
      opacity: 50%;
    }
  }

  & .name {
    padding: 0 10px;
    font-size: ${(props) => (props.imageSize === 'sm' ? '12px' : '14px')};
    font-weight: bold;
  }

  & .toggleable {
    border: 1px solid gainsboro;
    margin: 0 5px 0 10px;
    padding: 5px;
  }

  & .selected {
    border: 1px solid ${(props) => props.theme.colors.accent} !important;
    box-shadow: 0 0 5px 1px gainsboro;
  }

  & .info {
    display: inline;
    white-space: nowrap;
  }

  & .info:hover {
    cursor: pointer;
  }

  & .tooltip-wrapper .tooltip {
    top: 12px;
    left: -150px;
  }

  & .tooltip-wrapper {
    display: inline-flex;
  }

  & .leadTime-details {
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & .modal {
    position: fixed;
    top: 50%;
    left: 50%;
    background: red;
  }

  & .cfa-badge {
    height: 22px;
    width: 22px;
    border-radius: 50%;
  }

  @media (max-width: ${(props) => props.theme.small}) {
    flex: 0 1 50%;
  }

  @media (min-width: ${(props) => props.theme.phone}) {
    flex: 0 1 25%;
    & .toggleable:hover {
      border: 1px solid ${(props) => props.theme.colors.accent} !important;
      box-shadow: 0 0 5px 1px gainsboro;
    }
  }

  @media (min-width: ${(props) => props.theme.tablet}) {
    flex: 0 1 20%;
  }

  @media (min-width: ${(props) => props.theme.desktop}) {
    flex: 0 1 20%;
  }

  @media (max-width: ${(props) => props.theme.tablet}) {
    & .info {
      display: none;
    }
  }
`;

MenuItem.propTypes = {
  className: PropTypes.string.isRequired,
  item: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
      PropTypes.number,
      PropTypes.array,
      PropTypes.object,
    ]),
  ).isRequired,
  quantity: PropTypes.number,
  nutrition: PropTypes.objectOf(PropTypes.any),
  addToCart: PropTypes.func,
  hidePricing: PropTypes.bool,
  hideQuantityInput: PropTypes.bool,
  updateQuantity: PropTypes.func,
  toggleable: PropTypes.bool,
  recipe: PropTypes.bool,
};

MenuItem.defaultProps = {
  hidePricing: false,
  nutrition: {},
  hideQuantityInput: false,
  quantity: 0,
  addToCart: () => {},
  updateQuantity: () => {},
  toggleable: false,
  recipe: false,
};

export default memo(StyledMenuItem);
