import PropTypes from 'prop-types';
import { useEffect } from 'react';
import styled from 'styled-components';

import ModalHeader from '../ModalHeader/ModalHeader';

const addClass = () => document.body.classList.add('modal-open');
const removeClass = () => document.body.classList.remove('modal-open');

export const SlideModalComponent = ({ className, title, right, left, children, visible }) => {
  useEffect(() => (visible ? addClass() : removeClass()));

  return (
    <div className={`${className} ${visible ? 'visible' : ''}`} data-cy="modal" role="presentation">
      <ModalHeader left={left} right={right} title={title} />
      {children}
    </div>
  );
};

const SlideModal = styled(SlideModalComponent)`
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 3000;
  top: 0;
  left: 100%;
  background-color: white;
  overflow-x: hidden;
  will-change: left;
  transition: 0.35s all ease-out;
  @media (max-width: ${(props) => parseInt(props.theme.phone, 10) - 1}px) {
    top: 100vh;
    left: 0;
  }
  &.visible {
    top: 0;
    left: 0;
    @media (min-width: ${(props) => props.theme.phone}) {
      width: 60%;
      left: 40vw;
      box-shadow: 0 0 10px 1px #969494;
    }
    @media (min-width: ${(props) => props.theme.desktop}) {
      width: 50%;
      left: 50vw;
    }
  }
`;

SlideModalComponent.propTypes = {
  children: PropTypes.element,
  title: PropTypes.string.isRequired,
  right: PropTypes.element,
  left: PropTypes.element,
  className: PropTypes.string.isRequired,
  visible: PropTypes.bool,
};

SlideModalComponent.defaultProps = {
  children: null,
  left: null,
  right: null,
  visible: false,
};

export default SlideModal;
