import { Box, Flex } from '@cfacorp/cowponents';
import PropTypes from 'prop-types';

import { formatModifier, formatModifierPrice } from '../../util/format';

function Modifiers({ item, side, dessert }) {
  const { modifiers } = item;

  const renderComboItem = (comboItem) =>
    comboItem &&
    comboItem.name && (
      <Flex alignItems="center" key={comboItem.tag} lineHeight="25px">
        <Box textAlign="center" width="50px">
          {comboItem.displayQuantity}
        </Box>
        <Box m="0 20px" width="46%">
          {comboItem.name}
        </Box>
        <Box width="30%">{formatModifierPrice(comboItem.price)}</Box>
      </Flex>
    );

  return (
    <div>
      {modifiers &&
        modifiers.map((mod) => (
          <Flex alignItems="center" key={mod.tag} lineHeight="25px">
            <Box textAlign="center" width="50px">
              {mod.displayQuantity}
            </Box>
            <Box m="0 20px" width="46%">
              {formatModifier(mod)}
            </Box>
            <Box width="30%">{formatModifierPrice(mod.price)}</Box>
          </Flex>
        ))}
      {renderComboItem(side)}
      {renderComboItem(dessert)}
      <Box color="primary" lineHeight="25px" ml="70px">
        {item.specialInstructions || ''}
      </Box>
    </div>
  );
}

Modifiers.propTypes = {
  item: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
      PropTypes.number,
      PropTypes.object,
      PropTypes.array,
    ]),
  ).isRequired,
  side: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
      PropTypes.number,
      PropTypes.object,
      PropTypes.array,
    ]),
  ),
  dessert: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
      PropTypes.number,
      PropTypes.object,
      PropTypes.array,
    ]),
  ),
};

Modifiers.defaultProps = {
  side: {},
  dessert: {},
};

export default Modifiers;
