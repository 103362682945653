import { constructNow, isSameDay, startOfDay } from 'date-fns';
import { isEmpty, keys, pathOr, pluck } from 'ramda';
import { createSelector } from '@reduxjs/toolkit';

import ooeConstants from '../constants';
import { capitalizeFirstLetter, generateTimeSlots } from '../util/format';
import { isObjWithEmptyStrings, stripNonPOSSpecialCharacters } from '../util/utils';
import { validateDetails, validateGuest, validateSecondaryContact } from '../util/validate';
import { selectEditMode, selectPaperGoodsError } from './order';
import { selectBypassBusinessRules, selectLocationTimezone } from './user';
import { selectCardSelected } from './guest';

export type State = {
  guest?: {
    values?: {
      cfaId?: string;
      phone?: string;
      first?: string;
      last?: string;
      email?: string;
    };
    syncErrors?: ReturnType<typeof validateGuest>;
  };
  details?: {
    values?: {
      date?: Date;
      time?: string;
      cateringReason?: string;
      guestCount?: number;
      specialInstructions?: string;
    };
    syncErrors?: ReturnType<typeof validateDetails>;
  };
  secondaryContact?: {
    values?: {
      firstName?: string;
      lastName?: string;
      phoneNumber?: string;
    };
    syncErrors?: ReturnType<typeof validateSecondaryContact>;
  };
  paymentMethod?: {
    values?: {
      selectedMethod?: string;
    };
  };
};

export const types = {
  RESET_DATE_FORM_VALUES: 'Reset date form values',
  RESET_TIME_FORM_VALUES: 'Reset time form values',
  SET_CUSTOMER_PHONE_NUMBER: 'Set customer phone number',
  ADD_SECONDARY_CONTACT: 'Set secondary contact',
};

export const actions = {
  resetDateFormValues: () => ({ type: types.RESET_DATE_FORM_VALUES }),
  resetTimeFormValues: () => ({ type: types.RESET_TIME_FORM_VALUES }),
  setCustomerPhoneNumber: (phoneNumber: string) => ({
    type: types.SET_CUSTOMER_PHONE_NUMBER,
    phoneNumber,
  }),
  addSecondaryContact: () => ({ type: types.ADD_SECONDARY_CONTACT }),
};

const initialState: State = {};

export const selectForm = (state: { form: State }) => state.form || initialState;

// Guest Form selectors
export const selectGuestForm = createSelector(selectForm, (form) => form.guest || {});
export const selectGuestValues = createSelector(selectGuestForm, (guest) => guest.values || {});
export const selectCfaId = createSelector(selectGuestValues, (values) => values.cfaId);
export const selectPhoneForm = createSelector(selectGuestValues, (values) => values.phone);
export const selectPhoneFormForApi = createSelector(
  selectPhoneForm,
  (phone) => {
    if (!phone) {
      return '';
    }
    if (phone[0] === '+') {
      return `+1 ${phone.split(' ').slice(1).join('')}`;
    }
    return `+1 ${phone.split(' ').join('')}`;
  },
  // TODO Make this work for all country codes.
);
export const selectFirstName = createSelector(selectGuestValues, (values) =>
  stripNonPOSSpecialCharacters(values.first),
);
export const selectLastName = createSelector(selectGuestValues, (values) =>
  stripNonPOSSpecialCharacters(values.last),
);
export const selectFullName = createSelector(
  selectFirstName,
  selectLastName,
  (first, last) => `${first} ${last}`,
);
export const selectEmail = createSelector(selectGuestValues, (values) => values.email);
export const selectValidEmail = createSelector(
  selectGuestForm,
  (guest) => !guest.syncErrors || !guest.syncErrors.email,
);
export const selectValidPhone = createSelector(
  selectGuestForm,
  (guest) => !guest.syncErrors || !guest.syncErrors.phone,
);
export const selectGuestDetailsValid = createSelector(
  selectGuestForm,
  (guest) => !isEmpty(guest) && !guest.syncErrors,
);

export const formatPhoneNumber = (phone: string) => {
  if (phone && phone[0] !== '+') {
    return `+1 ${phone}`;
  }
  return phone;
};

export const selectGuestForProfileApi = createSelector(
  selectGuestValues,
  selectFirstName,
  selectLastName,
  selectFullName,
  (guestValues, firstName, lastName, fullName) => {
    const userObj: {
      name: { displayName?: string; givenName?: string; familyName?: string };
      phoneNumbers: Array<{ type: string; value: string }>;
      emails: Array<{ value: string; primary: boolean }>;
    } = {
      name: {},
      phoneNumbers: [],
      emails: [],
    };
    if (fullName) {
      userObj.name.displayName = fullName;
    }
    if (firstName) {
      userObj.name.givenName = firstName;
    }
    if (lastName) {
      userObj.name.familyName = lastName;
    }
    if (guestValues.phone) {
      userObj.phoneNumbers.push({
        type: 'Mobile',
        value: formatPhoneNumber(guestValues.phone),
      });
    }
    if (guestValues.email) {
      userObj.emails.push({
        value: guestValues.email,
        primary: true,
      });
    }
    return userObj;
  },
);

// Details form selectors
const selectDetailsForm = createSelector(selectForm, (form) => form.details || {});
export const selectDetailsFormValues = createSelector(selectDetailsForm, (details) =>
  pathOr({}, ['values'], details),
);
export const selectDetailsFormSyncErrors = createSelector(selectDetailsForm, (detailsForm) => {
  if (isEmpty(detailsForm)) {
    return ['date', 'time'];
  }
  return keys(detailsForm.syncErrors);
});
export const selectGuestFormSyncErrors = createSelector(selectGuestForm, (guestForm) => {
  function mapName(formFieldName: string) {
    if (formFieldName === 'first' || formFieldName === 'last') {
      return `${formFieldName} name`;
    }
    return formFieldName;
  }
  if (isEmpty(guestForm)) {
    return ['first', 'last', 'email', 'phone'].map(mapName);
  }
  return keys(guestForm.syncErrors).map(mapName);
});
export const selectFormSyncErrors = createSelector(
  selectGuestFormSyncErrors,
  selectDetailsFormSyncErrors,
  selectPaperGoodsError,
  (guestFormSyncErrors, detailsFormSyncErrors, paperGoodsError) => {
    const errorsArray = [...guestFormSyncErrors, ...detailsFormSyncErrors, ...paperGoodsError].map(
      capitalizeFirstLetter,
    );
    const { length } = errorsArray;
    return errorsArray.map((fieldName, idx) =>
      length > 2 && idx === length - 1 ? `and ${fieldName}` : fieldName,
    );
  },
);
export const selectFormSubmitWarning = createSelector(selectFormSyncErrors, (errors) => {
  if (errors.length === 0) {
    return {};
  }
  const prepositionToUse = errors.length === 1 ? 'is a' : 'are';
  const pluralizingLetter = errors.length === 1 ? '' : 's';
  return {
    type: 'error',
    message: `${errors.join(', ')} ${prepositionToUse} required field${pluralizingLetter}.`,
  };
});
export const selectDate = createSelector(selectDetailsFormValues, (formValues) =>
  pathOr('', ['date'], formValues),
);
export const selectTime = createSelector(selectDetailsFormValues, (formValues) =>
  pathOr('', ['time'], formValues),
);
export const selectDateIsToday = createSelector(selectEditMode, selectDate, (editMode, date) => {
  const today = startOfDay(constructNow(new Date()));
  return !editMode && isSameDay(date, today);
});
export const selectTimeShouldClear = createSelector(
  selectTime,
  selectDateIsToday,
  selectBypassBusinessRules,
  selectLocationTimezone,
  (time, dateIsToday, bypassBusinessRules, timeZone) => {
    const timeSlots = pluck(
      'time',
      generateTimeSlots(dateIsToday, timeZone).filter((timeSlot) => timeSlot.available),
    );
    const selectedTimeNotAvailable = timeSlots.indexOf(time) === -1;
    return bypassBusinessRules && selectedTimeNotAvailable;
  },
);
export const selectEventDetailsValid = createSelector(
  selectDetailsForm,
  selectPaperGoodsError,
  (details, paperGoodsError) => !isEmpty(details) && !details.syncErrors && paperGoodsError.length === 0,
);

export const selectCateringOccasion = createSelector(
  selectDetailsForm,
  (details) => details?.values?.cateringReason,
);

// Secondary contact selectors
export const selectSecondaryContact = createSelector(selectForm, (form) => form.secondaryContact || {});
export const selectSecondaryContactValues = createSelector(
  selectSecondaryContact,
  (contact) => contact.values || {},
);
export const selectSecondaryContactSyncErrors = createSelector(selectSecondaryContact, (contact) => {
  function mapName(formFieldName: string) {
    if (formFieldName === 'firstName' || formFieldName === 'lastName') {
      return `${formFieldName.slice(0, -4)} name`;
    }
    if (formFieldName === 'phoneNumber') {
      return `${formFieldName.slice(0, -6)} number`;
    }
    return formFieldName;
  }
  const areEmptyValues = isObjWithEmptyStrings(contact.values);
  if (!isEmpty(contact.values) && !areEmptyValues) {
    return keys(contact.syncErrors).map(mapName);
  }
  return [];
});

export const selectSecondaryContactSubmitWarning = createSelector(
  selectSecondaryContactSyncErrors,
  (errors) => {
    const errorsArray = errors.map(capitalizeFirstLetter);
    const { length } = errorsArray;
    const er = errorsArray.map((fieldName, idx) =>
      length > 1 && idx === length - 1 ? `and ${fieldName}` : fieldName,
    );
    if (er.length === 0) {
      return {};
    }
    const prepositionToUse = errors.length === 1 ? 'is a' : 'are';
    const pluralizingLetter = errors.length === 1 ? '' : 's';
    return {
      type: 'error',
      message: `Secondary POC ${er.join(' ')} ${prepositionToUse} required field${pluralizingLetter}.`,
    };
  },
);

export const selectSecondaryContactValid = createSelector(
  selectSecondaryContact,
  (contact) => !contact.syncErrors,
);

// Payment form selectors
export const selectPaymentForm = createSelector(selectForm, (form) => form.paymentMethod);
export const selectPaymentValid = createSelector(selectCardSelected, selectPaymentForm, (card, payment) => {
  if (payment && payment.values) {
    if (payment.values.selectedMethod === ooeConstants.CREDIT) {
      return !isEmpty(card) && card !== 'error';
    }
    return true;
  }
  return true;
});
export const selectPaymentSubmitWarning = createSelector(selectPaymentValid, (paymentValid) => {
  if (!paymentValid) {
    return {
      type: 'error',
      message: 'Payment method must be validated by entering the zip code associated with the selected card.',
    };
  }
  return {};
});
export const selectPayWithVaultedCard = createSelector(
  selectPaymentForm,
  selectPaymentValid,
  (form, paymentValid) =>
    form && form.values && form.values.selectedMethod === ooeConstants.CREDIT && paymentValid,
);
export const selectNewPaymentMethod = createSelector(
  selectPaymentForm,
  (form) => form && form.values && form.values.selectedMethod === ooeConstants.REQUEST_PAYMENT,
);
