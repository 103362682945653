/* eslint-disable react/forbid-prop-types */
import '@cfa/react-components/dist/styles.min.css';

import { bindActionCreators } from '@reduxjs/toolkit';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import AddNewCard from './components/AddNewCard/AddNewCard';
import DeviceStatusMessage from './components/DeviceStatusMessage/DeviceStatusMessage';
import ErrorMessage from './components/ErrorMessage/ErrorMessage';
import FullScreenError from './components/ErrorMessage/FullScreenError';
import Eula from './components/Eula/Eula';
import Footer from './components/Footer/Footer';
import Icon from './components/Icon';
import InitialLoad from './components/InitialLoad/InitialLoad';
import Logo from './components/Logo/Logo';
import CartLink from './components/NavComponents/CartLink';
import RightDesktop from './components/NavComponents/RightDesktop';
import UserLocation from './components/NavComponents/UserLocation';
import Navigation from './components/Navigation/Navigation';
import Overlay from './components/Overlay/Overlay';
import MoreTab from './components/Tabs/MoreTab';
import NavTab from './components/Tabs/NavTab';
import Tabs from './components/Tabs/Tabs';
import ooeConstants from './constants';
import ConnectedCart from './containers/Cart';
import Dashboard from './containers/Dashboard';
import ConnectedEventDetails from './containers/EventDetails';
import ConnectedFloatingMenu from './containers/FloatingMenu';
import ConnectedGuest from './containers/Guest';
import ConnectedMenu from './containers/Menu';
import More from './containers/More';
import ConnectedPayment from './containers/Payment';
import ConnectedSubmit from './containers/Submit';
import { selectErrorsAndWarnings, selectTaxAndTotal } from './reducers';
import { selectCartItemsLength } from './reducers/cart';
import { actions as dashboardActions } from './reducers/dashboard';
import { selectDisplayMessage, selectIsDeviceOnline } from './reducers/device';
import { actions as formActions } from './reducers/form';
import { actions as guestActions } from './reducers/guest';
import { actions as menuActions } from './reducers/menu';
import { actions as orderActions, selectDestination, selectOrderIsLoading } from './reducers/order';
import {
  actions as userActions,
  isAdminUser as getIsAdminUserFromState,
  selectAllStoreNamesAndNumbers,
  selectAppLoadingComplete,
  selectEulaText,
  selectIsUserLoggedIn,
  selectLocationDropdownDisabled,
  selectLocationNumber,
  selectOktaTokenError,
  selectShouldBypassBusinessRules,
  selectShowHardcodedEulaText,
  selectUserHasAgreedToEula,
  selectUserLocations,
  selectUserName,
} from './reducers/user';
import { logPageView } from './services/analytics';
import theme from './theme';
import usePrevious from './util/customHooks';
import browserHistory from './util/history';
import { getQueryVariable } from './util/routerHelpers';

export const App = (props) => {
  const {
    location,
    getTokenFromStorage,
    setCustomerPhoneNumber,
    addExternalOrderId,
    history,
    cartItemCount,
    cartTotal,
    cartLoading,
    destination,
    userName,
    locations,
    updateUserLocation,
    selectedLocation,
    appLoadingComplete,
    errors,
    logoutUser,
    userHasAgreedToEula,
    addUserToAcknowledgement,
    eulaText,
    oktaError,
    throwFullScreenError,
    dismissError,
    isDeviceOnline,
    displayDeviceMessage,
    userIsLoggedIn,
    locationDropdownDisabled,
    shouldBypassBusinessRules,
    toggleBypass,
    lookupLocation,
    isAdminUser,
    allStoreNamesAndNumbers,
    isHardcodedEulaShown,
    resetDateFormValues,
    resetTimeFormValues,
  } = props;

  let previousLocation = location;

  useEffect(() => {
    const { search, pathname, hash } = previousLocation;
    const routeToStore = `${pathname}${search}`;
    let storedRoute = localStorage.getItem(ooeConstants.ROUTE_TO_REDIRECT_TO);
    if (!storedRoute) {
      localStorage.setItem(ooeConstants.ROUTE_TO_REDIRECT_TO, routeToStore);
    }

    getTokenFromStorage();

    storedRoute = localStorage.getItem(ooeConstants.ROUTE_TO_REDIRECT_TO);
    if (storedRoute && hash) {
      browserHistory.replace(storedRoute);
      localStorage.removeItem(ooeConstants.ROUTE_TO_REDIRECT_TO);
    }

    if (getQueryVariable('phoneNumber')) {
      setCustomerPhoneNumber(getQueryVariable('phoneNumber'));
    }

    if (getQueryVariable('callId')) {
      const callSource = getQueryVariable('callSource') || ooeConstants.VCA_INKTEL;
      addExternalOrderId(getQueryVariable('callId'), callSource);
    }

    browserHistory.listen((loc) => {
      logPageView(loc.pathname);
    });
  }, []);

  const prevProps = usePrevious({ location });
  const prevPropsLocation = prevProps?.location;

  useEffect(() => {
    if (history?.action !== 'POP' && (!prevPropsLocation?.state || !prevPropsLocation?.state?.modal)) {
      previousLocation = prevProps?.location;
    }
  });

  const { pathname } = location;
  const isModal = !!(location.state && location.state.modal);
  const isCardModal = !!(location.state && location.state.cardModal);

  if (oktaError && userIsLoggedIn && window.location.pathname !== '/error') {
    throwFullScreenError();
  }

  const goBackState = {
    modal: false,
    cardModal: false,
    cartItemId: null,
  };

  return (
    <ThemeProvider theme={theme}>
      <>
        {(isModal || isCardModal) && <Overlay goBack={() => history.push({ state: goBackState })} />}
        {appLoadingComplete && !userHasAgreedToEula && !window.Cypress && (
          <Eula
            addUserToEula={addUserToAcknowledgement}
            eulaText={eulaText}
            isHardcodedEulaShown={isHardcodedEulaShown}
            logoutUser={logoutUser}
          />
        )}
        {(appLoadingComplete || window.location.pathname === '/error') && (
          <div style={{ minHeight: '97vh' }}>
            {displayDeviceMessage && <DeviceStatusMessage isDeviceOnline={isDeviceOnline} />}
            <Navigation
              leftDesktop={<Logo />}
              leftMobile={
                !oktaError ? (
                  <UserLocation
                    allStoreNamesAndNumbers={allStoreNamesAndNumbers}
                    isAdminUser={isAdminUser}
                    locationDropdownDisabled={locationDropdownDisabled}
                    locations={locations}
                    lookupLocation={lookupLocation}
                    resetDateFormValues={resetDateFormValues}
                    resetTimeFormValues={resetTimeFormValues}
                    selectedLocation={selectedLocation}
                    updateLocation={updateUserLocation}
                    userName={userName}
                  />
                ) : null
              }
              pathname={pathname}
              rightDesktop={
                !oktaError ? (
                  <RightDesktop
                    allStoreNamesAndNumbers={allStoreNamesAndNumbers}
                    cartItemCount={cartItemCount}
                    icon="bag"
                    isAdminUser={isAdminUser}
                    loading={cartLoading}
                    locationDropdownDisabled={locationDropdownDisabled}
                    locations={locations}
                    logoutUser={logoutUser}
                    lookupLocation={lookupLocation}
                    resetDateFormValues={resetDateFormValues}
                    resetTimeFormValues={resetTimeFormValues}
                    selectedLocation={selectedLocation}
                    shouldBypassBusinessRules={shouldBypassBusinessRules}
                    toggleBypass={toggleBypass}
                    total={cartTotal}
                    updateLocation={updateUserLocation}
                    userName={userName}
                  />
                ) : null
              }
              rightMobile={
                <CartLink cartItemCount={cartItemCount} icon="bag" loading={cartLoading} total={cartTotal} />
              }
            >
              <Tabs>
                <NavTab activeOnlyWhenExact icon={<Icon>man</Icon>} title="Guest" to="/" />
                <NavTab activeOnlyWhenExact icon={<Icon>calendar</Icon>} title="Details" to="/event" />
                <NavTab activeOnlyWhenExact icon={<Icon>sandwich</Icon>} title="Menu" to="/menu" />
                <NavTab activeOnlyWhenExact icon={<Icon>payment</Icon>} title="Payment" to="/payment" />
                <MoreTab icon={<Icon>menu</Icon>} location={selectedLocation} title="More" to="/more" />
              </Tabs>
            </Navigation>
            {errors && <ErrorMessage destination={destination} dismissError={dismissError} errors={errors} />}
            <Switch location={isModal ? previousLocation : location}>
              <Route component={ConnectedGuest} exact path="/" />
              <Route component={ConnectedEventDetails} exact path="/event" />
              <Route component={ConnectedMenu} exact path="/menu" />
              <Route component={ConnectedPayment} exact path="/payment" />
              <Route component={ConnectedSubmit} exact path="/submit" />
              <Route component={Dashboard} exact path="/more/dashboard" />
              <Route component={More} exact path="/more" />
              <Route component={FullScreenError} exact path="/error" />
            </Switch>
            <AddNewCard history={history} visible={isCardModal} />
            <ConnectedFloatingMenu />
            <ConnectedCart cartItemId={location.state && location.state.cartItemId} visible={isModal} />
          </div>
        )}

        {!appLoadingComplete && window.location.pathname !== '/error' && <InitialLoad />}
        <Footer>{`Version ${ooeConstants.APP_VERSION}`}</Footer>
      </>
    </ThemeProvider>
  );
};

App.propTypes = {
  addExternalOrderId: PropTypes.func.isRequired,
  setCustomerPhoneNumber: PropTypes.func.isRequired,
  getTokenFromStorage: PropTypes.func.isRequired,
  userName: PropTypes.string,
  cartItemCount: PropTypes.number.isRequired,
  cartTotal: PropTypes.string,
  locations: PropTypes.arrayOf(PropTypes.object).isRequired,
  cartLoading: PropTypes.bool,
  location: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object])).isRequired,
  history: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.object,
      PropTypes.number,
      PropTypes.string,
      PropTypes.array,
    ]),
  ).isRequired,
  updateUserLocation: PropTypes.func,
  selectedLocation: PropTypes.string,
  appLoadingComplete: PropTypes.bool,
  errors: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.bool])),
  logoutUser: PropTypes.func,
  userHasAgreedToEula: PropTypes.bool,
  addUserToAcknowledgement: PropTypes.func.isRequired,
  eulaText: PropTypes.string,
  oktaError: PropTypes.bool,
  throwFullScreenError: PropTypes.func,
  destination: PropTypes.string,
  dismissError: PropTypes.func,
  isDeviceOnline: PropTypes.bool,
  displayDeviceMessage: PropTypes.bool,
  shouldBypassBusinessRules: PropTypes.bool,
  toggleBypass: PropTypes.func.isRequired,
  userIsLoggedIn: PropTypes.bool.isRequired,
  locationDropdownDisabled: PropTypes.bool,
  lookupLocation: PropTypes.func,
  isAdminUser: PropTypes.bool,
  allStoreNamesAndNumbers: PropTypes.arrayOf(PropTypes.object),
  isHardcodedEulaShown: PropTypes.bool,
  resetDateFormValues: PropTypes.func,
  resetTimeFormValues: PropTypes.func,
};

App.defaultProps = {
  userName: '',
  cartLoading: false,
  cartTotal: '$0.00',
  updateUserLocation: () => {},
  selectedLocation: '',
  appLoadingComplete: false,
  errors: {},
  logoutUser: () => {},
  userHasAgreedToEula: true,
  eulaText: '',
  oktaError: false,
  throwFullScreenError: () => {},
  destination: 'Delivery',
  dismissError: () => {},
  isDeviceOnline: true,
  displayDeviceMessage: false,
  locationDropdownDisabled: false,
  lookupLocation: () => {},
  isAdminUser: false,
  shouldBypassBusinessRules: true,
  allStoreNamesAndNumbers: [],
  isHardcodedEulaShown: false,
};

function mapStateToProps(state) {
  return {
    cartItemCount: selectCartItemsLength(state),
    cartTotal: selectTaxAndTotal(state),
    cartLoading: selectOrderIsLoading(state),
    destination: selectDestination(state),
    userName: selectUserName(state),
    locations: selectUserLocations(state),
    selectedLocation: selectLocationNumber(state),
    appLoadingComplete: selectAppLoadingComplete(state),
    errors: selectErrorsAndWarnings(state),
    userHasAgreedToEula: selectUserHasAgreedToEula(state),
    eulaText: selectEulaText(state),
    oktaError: selectOktaTokenError(state),
    isDeviceOnline: selectIsDeviceOnline(state),
    displayDeviceMessage: selectDisplayMessage(state),
    userIsLoggedIn: selectIsUserLoggedIn(state),
    locationDropdownDisabled: selectLocationDropdownDisabled(state),
    shouldBypassBusinessRules: selectShouldBypassBusinessRules(state),
    isAdminUser: getIsAdminUserFromState(state),
    allStoreNamesAndNumbers: selectAllStoreNamesAndNumbers(state),
    isHardcodedEulaShown: selectShowHardcodedEulaText(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...menuActions,
      ...userActions,
      ...guestActions,
      ...formActions,
      ...orderActions,
      ...dashboardActions,
    },
    dispatch,
  );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
