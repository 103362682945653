import { Action, ActionCreator } from '@reduxjs/toolkit';
import { isEmpty, pathOr } from 'ramda';
import { ActionsObservable, ofType } from 'redux-observable';
import { concat, Observable, of } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';

import {
  actions as guestActions,
  selectApiKey,
  selectGuestTokenExpiryMs,
  selectRefreshToken as selectGuestRefreshToken,
  types as guestTypes,
} from '../reducers/guest';
import {
  actions as userActions,
  selectAccessToken as selectCfaAccessToken,
  selectAccessTokenExpirationDate as selectCfaAccessTokenExpirationDate,
  selectRefreshToken as selectCfaRefreshToken,
  types as userTypes,
} from '../reducers/user';
import { ApiCall } from './requestFactory';

const retryOrFail: <T>(props: {
  requestFactory: ApiCall<T>;
  failure: ActionCreator<Action>;
  success: ActionCreator<Action>;
  store: { value: any };
}) => Observable<any> = ({ requestFactory, failure, success, store }) => {
  const state = store.value;
  const apiKey = selectApiKey(state);
  const accessToken = selectCfaAccessToken(state);
  const tokens = { apiKey, accessToken };
  return requestFactory.retry(tokens).pipe(
    map((res) => success(res)),
    catchError(() => {
      return retryOrFail({
        requestFactory,
        failure,
        success,
        store,
      });
    }),
  );
};

const refreshTokensIfNecessary = <T>(store: { value: any }, requestFactory: ApiCall<T>) => {
  const state = store.value;
  const guestRefreshToken = selectGuestRefreshToken(state);
  const guestTokenExpiryMs = selectGuestTokenExpiryMs(state);
  const cfaRefreshToken = selectCfaRefreshToken(state);
  const cfaAccessTokenExpirationDate = selectCfaAccessTokenExpirationDate(state);
  const guestTokenExpired = guestRefreshToken && guestTokenExpiryMs && guestTokenExpiryMs < Date.now();
  const cfaTokenExpired =
    cfaRefreshToken &&
    cfaAccessTokenExpirationDate &&
    new Date(cfaAccessTokenExpirationDate * 1000).valueOf() < Date.now();

  let awaitRefresh;
  if (guestTokenExpired) {
    if (pathOr('', ['auth', 'type'], requestFactory).indexOf('Bearer') === 0) {
      awaitRefresh = (guestActions as unknown as { refreshToken: ActionCreator<Action> }).refreshToken();
    }
  }

  if (cfaTokenExpired) {
    if (pathOr('', ['auth', 'type'], requestFactory).indexOf('JWTBearer') === 0) {
      awaitRefresh = (userActions as unknown as { refreshOktaToken: ActionCreator<Action> }).refreshOktaToken(
        cfaRefreshToken,
      );
    }
  }
  return awaitRefresh;
};

export default function myEpicHelper<T>(
  requestFactory: ApiCall<T>,
  success: ActionCreator<Action>,
  failure: ActionCreator<Action>,
  options: { store: { value: any }; action$: ActionsObservable<Action> },
) {
  const { store, action$ } = options;

  const requestFactoryToUse = {
    ...requestFactory,
    execute: requestFactory.execute || (() => requestFactory),
    retry: requestFactory.retry || requestFactory.execute,
  } as ApiCall<T>;

  const retryAction$ = action$.pipe(
    ofType(guestTypes.REFRESH_TOKEN_SUCCESS, userTypes.REFRESH_TOKEN_SUCCESS),
    take(1),
    switchMap(() =>
      retryOrFail({
        requestFactory: requestFactoryToUse,
        failure,
        success,
        store,
      }),
    ),
  );

  const refreshAction = refreshTokensIfNecessary(store, requestFactory);
  if (refreshAction) {
    return concat(of(refreshAction), retryAction$);
  }

  return requestFactoryToUse.execute().pipe(
    map((res) => success(res)),
    catchError((err) => {
      if (err.needsRefresh && !isEmpty(requestFactoryToUse.auth)) {
        if (refreshTokensIfNecessary(store, requestFactoryToUse)) {
          return retryAction$;
        }
      }
      return of(failure(err.toString()));
    }),
  );
}

export function epicOptions(store: { value: any }, action$: ActionsObservable<Action>) {
  return {
    store,
    action$,
  };
}
