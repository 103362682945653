export const statusCodes = {
  600: {
    message:
      'An item in your cart was not available for {DESTINATION} at this location. The item has been removed from the cart. [DISMISSIBLE]',
    type: 'Warning',
  },
  1301: {
    message: 'Uh oh! Something went wrong. Please try again or contact support.',
    type: 'Error',
  },
  1311: {
    message: 'This store does not accept Delivery orders. Please select Pickup.',
    type: 'Error',
  },
  1314: {
    message:
      'Cannot order breakfast items after breakfast hours. Please select a new time, or remove breakfast items.',
    type: 'Error',
  },
  1320: {
    message: 'The restaurant POS server may be offline. Please ensure that it is operational and try again.',
    type: 'error',
  },
  1378: {
    message:
      'This item has been locked by the restaurant operator. Please remove it from the cart to proceed.',
    type: 'Error',
  },
  1414: {
    message: 'No time slots available for the selected date. Please select a new date and time.',
    type: 'Error',
  },
  1416: {
    message:
      'No available breakfast time slots found for day selected. Please select a new date and time, or remove breakfast items. [DISMISSIBLE]',
    type: 'Error',
  },
  1420: {
    message:
      'An item in your cart is not available for the selected destination at this location. Please remove the item from the cart.',
    type: 'Error',
  },
  1421: {
    message:
      'An item in your cart was not available for the selected destination at this location. The item has been removed from the cart. [DISMISSIBLE]',
    type: 'Warning',
  },
  1430: {
    message:
      'Oops! Something went wrong with editing your order. Please refresh the page and try to edit again, or edit the order on the POS.',
    type: 'Error',
  },
  1433: {
    message: 'Each favorite name must be a unique. Please try a different name.',
    type: 'Error',
  },
};

export const addressErrorMessages = {
  ERR_NOT_DELIVERABLE: {
    message: 'The address entered could not be validated.',
    heading: 'Valid Address Not Found',
    type: 'Error',
  },
  ERR_OUT_OF_RANGE: {
    key: 'OUT_OF_RANGE',
    message: 'The address entered is outside of delivery range',
    heading: 'Out of Delivery Range',
    type: 'Warning',
  },
};

export const DOPErrorMessages = {
  DESTINATION_INVALID:
    'Error: This location does not accept orders for the selected destination. Please change location or destination.',
};

export const userErrorMessages = {
  NO_TOKEN: 'Error: Uh oh! Something went wrong. Please try again or contact support.',
};

export const guestErrorMessages = {
  MASQUERADE_FAILED:
    'Error: We had trouble selecting this guest. Please try again or try another guest. [DISMISSIBLE]',
};

export const dashboardErrorMessages = {
  CANCEL_ERROR: 'There was a problem cancelling this order, please try again.',
  RESEND_EMAIL_ERROR: 'There was a problem resending the payment email, please try again.',
  TOO_LATE_TO_CANCEL: 'Too late to take action on this order.',
  DETAILS_ERROR: "There was a problem retrieving the customer's phone number and email. Please try again.",
  NO_PHONE: "There is no phone number associated with this customer's account.",
  NO_EMAIL: "There is no email address associated with this customer's account.",
  ORDER_ERROR: 'There was a problem retrieving the order details for this order. Please try again.',
  POS_ORDER: 'This order was placed on the Restaurant Point of Sale. Please make changes on the POS.',
  ERROR_STATUS:
    'Something went wrong with the submission of this order. Please re-enter the order to submit.',
  GET_EXCEL_DATA_ERROR: "One or more orders doesn't load properly. Please try again",
};

export const locationErrorMessages = {
  NO_LOCATIONS: 'No locations were found, please try again.',
};

export const dateTimeErrorMessages = {
  DATES_FAILURE:
    "Error: Uh oh! We're having trouble retrieving available dates. Please try again or contact support.",
  TIMES_FAILURE:
    "Error: Uh oh! We're having trouble retrieving available times. Please try again or contact support.",
};

export const submitWarningErrorMessages = {
  CFA_GUEST:
    'Chick-fil-A One account was not selected. Please select an account on the Guest tab for the guest to earn points.',
  DELIVERY_MIN: 'Subtotal is less than minimum delivery order amount of ',
  PICKUP_MIN: 'Subtotal is less than minimum pickup order amount of ',
  ORDER_MAX: 'Subtotal is more than maximum order amount of ',
  SAME_DAY_ORDER_MAX: 'Subtotal is more than maximum order same day order amount of ',
  ADDRESS_WARNING: 'Delivery address could not be validated. Please update the address.',
  MISSING_FIELDS: 'Please complete all delivery location fields noted as required to process order.',
  DELIVERY_RANGE: 'Out of delivery range',
};

export const editWarningMessage = 'Warning: You are editing an order for ';
