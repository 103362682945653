/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/jsx-one-expression-per-line */
import { ToggleButton, ToggleButtonGroup } from '@cfa/react-components';
import { Flex } from '@cfacorp/cowponents';
import PropTypes from 'prop-types';
import { useState } from 'react';
import styled from 'styled-components';

import Icon from '../Icon';
import Tooltip from '../Tooltip/Tooltip';
import PaperGoodsBadges from './PaperGoodsBadges';
import PaperGoodsOptions from './PaperGoodsOptions';

const PaperGoods = ({
  setPaperGoodsOptions,
  paperGoodsOptions,
  paperGoodsYesOrNo,
  isEditMode,
  isCMTOrder,
  paperGoodsBool,
}) => {
  let selectPaperGoodsYesOrNo = paperGoodsYesOrNo;
  const [paperGoodsOptionsExpanded, setExpandPaperGoods] = useState(false);
  const onPaperGoodsHandler = (event) => {
    if (event.target.value === 'yes') {
      setExpandPaperGoods((prev) => !prev);
    } else {
      setPaperGoodsOptions('', false, true);
      setExpandPaperGoods(false);
    }
  };
  if (isEditMode && !isCMTOrder) {
    selectPaperGoodsYesOrNo = paperGoodsBool ? 'yes' : 'no';
  }
  const onCollapsehandler = () => !isEditMode && setExpandPaperGoods((prev) => !prev);
  const paperGoodsSegmentButtons =
    isEditMode && isCMTOrder ? (
      <Tooltip
        className="tooltip-box"
        tooltipContent={
          <div>
            <span className="title">Please Edit Paper Goods in Special Instructions Field</span>
          </div>
        }
      >
        <ToggleButtonGroup onChange={onPaperGoodsHandler} size="sm">
          <StyledToggleButton disabled={isEditMode} key="no" value="no">
            No
          </StyledToggleButton>
          <StyledToggleButton disabled={isEditMode} key="yes" value="yes">
            Yes
          </StyledToggleButton>
        </ToggleButtonGroup>
      </Tooltip>
    ) : (
      <ToggleButtonGroup defaultValue={selectPaperGoodsYesOrNo} onChange={onPaperGoodsHandler} size="sm">
        <StyledToggleButton data-cy="no-paper-goods" key="no" value="no">
          No
        </StyledToggleButton>
        <StyledToggleButton data-cy="yes-paper-goods" key="yes" value="yes">
          Yes
        </StyledToggleButton>
      </ToggleButtonGroup>
    );
  return (
    <StyledPaperGoods>
      <Flex alignItems="center" className="paper-goods-navigation">
        <div className="button-box">
          {paperGoodsSegmentButtons}
          <Arrow onClick={onCollapsehandler} rotateArrow={paperGoodsOptionsExpanded}>
            <Icon className="arrow">down</Icon>
          </Arrow>
        </div>
        {!paperGoodsOptionsExpanded && (
          <PaperGoodsBadges
            paperGoodsOptions={paperGoodsOptions}
            setPaperGoodsOptions={setPaperGoodsOptions}
          />
        )}
      </Flex>
      {paperGoodsOptionsExpanded && (
        <PaperGoodsOptions
          paperGoodsOptions={paperGoodsOptions}
          setPaperGoodsOptions={setPaperGoodsOptions}
        />
      )}
    </StyledPaperGoods>
  );
};

const StyledToggleButton = styled(ToggleButton)`
  width: 80px;
`;

const StyledPaperGoods = styled('div')`
  & .button-box {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-right: 40px;
    @media (max-width: 600px) {
      margin-right: 0;
    }
  }
  @media (max-width: 600px) {
    & .paper-goods-navigation {
      flex-direction: column;
    }
  }
`;

const Arrow = styled('span')`
  max-width: 20px;
  max-height: 20px;
  margin: 0;
  background-color: ${(props) => props.theme.colors.primary};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: -40px;
  cursor: pointer;
  & svg {
    max-width: 100%;
    max-height: 100%;
    margin: 0;
    fill: ${(props) => props.theme.colors.secondary};
    transform: ${(props) => props.rotateArrow && 'rotateX(180deg)'};
  }
`;

PaperGoods.propTypes = {
  setPaperGoodsOptions: PropTypes.func,
  paperGoodsOptions: PropTypes.objectOf(PropTypes.bool),
  paperGoodsYesOrNo: PropTypes.string,
  isEditMode: PropTypes.bool,
  isCMTOrder: PropTypes.bool,
  paperGoodsBool: PropTypes.bool,
};

PaperGoods.defaultProps = {
  setPaperGoodsOptions: () => {},
  paperGoodsOptions: {},
  paperGoodsYesOrNo: '',
  isEditMode: false,
  isCMTOrder: false,
  paperGoodsBool: false,
};

export default PaperGoods;
