import PropTypes from 'prop-types';
import styled from 'styled-components';

import svg from './icon-sprite.svg';

// const imageUrl = window.cypressIsLoggedIn ? null : require('./icon-sprite.svg');

function Icon({ children, height, width, margin, onClick, className }) {
  const iconText = `this does not exist - ${children}`;
  return !window.cypressIsLoggedIn ? (
    <StyledIcon
      className={[`icon-${children.toLowerCase().replace(' ', '_')}`, className].join(' ')}
      height={height}
      margin={margin}
      onClick={onClick}
      width={width}
    >
      <use xlinkHref={`${svg}#${children}`}>{iconText}</use>
    </StyledIcon>
  ) : null;
}

const StyledIcon = styled.svg`
  max-width: ${(props) => `${props.width}px`};
  max-height: ${(props) => `${props.height}px`};
  margin: ${(props) => props.margin};
`;

Icon.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  margin: PropTypes.string,
  children: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

Icon.defaultProps = {
  height: '35',
  width: '35',
  margin: '10px',
  onClick: () => {},
  className: '',
};

export default Icon;
