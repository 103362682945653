import 'react-datepicker/dist/react-datepicker.css';

import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';

import { isValidOrderDate } from '../../util/format';

const DatePickerInput = (props) => {
  const {
    input,
    availableDates,
    loading,
    bypassBusinessRules,
    startDate,
    endDate,
    onDatesChange,
    selectRange,
  } = props;

  return selectRange && onDatesChange ? (
    <DatePicker
      autocomplete="off"
      dropdownMode="select"
      endDate={endDate}
      inline={false}
      monthsShown={2}
      name="Select Date"
      onChange={(dates) => onDatesChange({ startDate: dates[0], endDate: dates[1] })}
      placeholderText="Select Date Range"
      selectsRange
      startDate={startDate}
    />
  ) : (
    <DatePicker
      autocomplete="off"
      disabled={loading}
      dropdownMode="select"
      filterDate={(date) => isValidOrderDate(date, availableDates, bypassBusinessRules)}
      monthsShown={1}
      name="Select Date"
      onChange={(date) => input?.onChange(date)}
      placeholderText={loading ? 'Retrieving Available Dates...' : 'Select Date'}
      selected={input.value}
    />
  );
};

DatePickerInput.propTypes = {
  input: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.object])),
  startDate: PropTypes.any,
  endDate: PropTypes.any,
  onDatesChange: PropTypes.func,
  availableDates: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
  bypassBusinessRules: PropTypes.bool,
  selectRange: PropTypes.bool,
};

DatePickerInput.defaultProps = {
  input: {},
  loading: true,
  bypassBusinessRules: false,
  selectRange: false,
  availableDates: [],
  startDate: null,
  endDate: null,
  onDatesChange: null,
};

export default DatePickerInput;
