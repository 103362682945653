import amplitude from 'amplitude-js';

import ooeConstants from '../constants';
import { leaveBreadcrumb } from './bugsnag';

let amplitudeEnabled = false;

const setAmplitudeEnabled = (enabled: boolean) => {
  amplitudeEnabled = !!enabled;
};

const setupAmplitude = () => {
  if (!ooeConstants.AMPLITUDE_ENABLED) {
    setAmplitudeEnabled(false);
    return false;
  }

  leaveBreadcrumb('Set up Amplitude', {
    message: 'Amplitude initializing',
  });

  setAmplitudeEnabled(true);
  amplitude.getInstance().init(ooeConstants.AMPLITUDE_API_KEY, undefined, { secureCookie: true });
  amplitude.getInstance().setVersionName(ooeConstants.APP_VERSION);
  return true;
};

const logAmplitudeEvent = (event: string, properties: object) => {
  if (!amplitudeEnabled) {
    return;
  }
  amplitude.getInstance().logEvent(event, properties);
};

const setAmplitudeUser = (userId: string, userInfo = {}) => {
  if (!amplitudeEnabled) {
    return;
  }
  amplitude.getInstance().setUserId(userId);
  amplitude.getInstance().setUserProperties(userInfo);
};

export { setupAmplitude, logAmplitudeEvent, setAmplitudeUser };
