import { Button } from '@cfa/react-components';
import { Section, SectionHeader } from '@cfacorp/cowponents';

const setLocationToOrigin = () => {
  window.location.href = window.location.origin;
};

const FullScreenError = () => (
  <Section style={{ minHeight: 200 }}>
    <SectionHeader>Oops!</SectionHeader>
    <p>There was an issue retrieving your Chick-fil-a account. Please try again or contact support.</p>
    <Button onClick={setLocationToOrigin} variant="secondary">
      Try Again
    </Button>
  </Section>
);

export default FullScreenError;
