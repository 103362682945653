import { Button } from '@cfa/react-components';
import { Box, Flex, Input, Spinner } from '@cfacorp/cowponents';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import Error from '../../assets/error.svg';
import Success from '../../assets/success.svg';
import ooeConstants from '../../constants';
import { isExpired } from '../../util/validate';
import Icon from '../Icon';

export const VaultedCards = (props) => {
  const { cardSelected, validateZipSuccess, validateZip, zipLoading, vaultedCards, cardsLoading } = props;
  const [cardToValidate, setCardToValidate] = useState(null);
  const [zip, setZip] = useState('');

  useEffect(() => {
    setCardToValidate(cardSelected && cardSelected.id ? cardSelected.generatedId : null);
  }, [cardSelected]);

  const toggleValidateMode = (e, card) => {
    e.preventDefault();
    if (card.validated) {
      validateZipSuccess([card]);
    }
    setCardToValidate(card.generatedId);
    setZip('');
  };

  const callValidateZip = (event, card) => {
    const enteredZip = event.target.value;
    setZip(enteredZip);
    if (enteredZip.length >= 5) {
      validateZip(enteredZip, card);
    }
  };

  const renderZipValidation = (card) => {
    if (cardToValidate === card.generatedId || cardSelected.generatedId === card.generatedId) {
      if (zipLoading) {
        return <Spinner className="spinner" />;
      }
      if (card.validated) {
        return <img alt="Success" className="success" src={Success} />;
      }
      return (
        <>
          <Input
            autoFocus
            data-cy="card-zip"
            height="37px"
            m="0.5em"
            onChange={(e) => callValidateZip(e, card)}
            placeholder="Zip"
            value={zip}
            width="75px"
          />
          {cardSelected === 'error' && <img alt="Error" className="error" src={Error} />}
        </>
      );
    }
    if (isExpired(card)) {
      return <div>Card Expired</div>;
    }
    return (
      <Button color="secondary" onClick={(e) => toggleValidateMode(e, card)} style={{ minWidth: 'unset' }}>
        Select
      </Button>
    );
  };

  return (
    <StyledVaultedCards>
      {cardsLoading ? (
        <Flex>
          <Spinner className="card-spinner" />
        </Flex>
      ) : (
        vaultedCards?.map?.((card) => (
          <Flex alignItems="center" className={isExpired(card) && 'expired'} key={card.generatedId}>
            <Box width={1 / 6}>
              <Icon>{card.cardType?.toLowerCase?.()}</Icon>
            </Box>
            <Flex alignItems="center" width={1 / 3}>
              {card.cardType !== ooeConstants.paypal && (
                <>
                  <span className="card-number">
                    &#9679;&#9679;&#9679;&#9679; &#9679;&#9679;&#9679;&#9679;&nbsp;
                  </span>
                  &#9679;&#9679;&#9679;&#9679;
                </>
              )}
              {card.accountDisplay}
            </Flex>
            <Box className="zip-container" width={5 / 12}>
              {renderZipValidation(card)}
            </Box>
          </Flex>
        ))
      )}
    </StyledVaultedCards>
  );
};

const StyledVaultedCards = styled.div`
  clear: both;

  & .zip-container {
    display: flex;
    position: relative;
    justify-content: flex-end;
    align-items: center;
  }

  & .spinner {
    text-align: right !important;
  }
  & .success {
    width: 28px;
    margin-right: 30px;
  }
  & .error {
    width: 23px;
    position: absolute;
    right: -25px;
    top: 14px;
  }
  & .expired {
    opacity: 0.3;
    pointer-events: none;
  }

  @media (max-width: ${(props) => props.theme.phone}) {
    & .card-number {
      display: none;
    }
  }
`;

VaultedCards.propTypes = {
  vaultedCards: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object])),
  cardsLoading: PropTypes.bool,
  validateZip: PropTypes.func.isRequired,
  zipLoading: PropTypes.bool.isRequired,
  cardSelected: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.string]).isRequired,
  validateZipSuccess: PropTypes.func.isRequired,
};

VaultedCards.defaultProps = {
  vaultedCards: [],
  cardsLoading: false,
};

export default VaultedCards;
