import { Select } from '@cfacorp/cowponents';
import PropTypes from 'prop-types';

const SelectCateringOccasion = (props) => {
  const { handleCateringOccasion, cateringOccasionValue } = props;
  return (
    <Select
      className="cateringReason"
      label="Catering Occasion"
      name="cateringReason"
      onChange={handleCateringOccasion}
      placeholder="Catering Occasion (Optional)"
      value={cateringOccasionValue}
    >
      <option value="Birthday Party">Birthday Party</option>
      <option value="Family or Friends Gathering">Family or Friends Gathering</option>
      <option value="Office Meeting">Office Meeting</option>
      <option value="Company Event">Company Event</option>
      <option value="School Event">School Event</option>
      <option value="None">None</option>
    </Select>
  );
};

SelectCateringOccasion.propTypes = {
  handleCateringOccasion: PropTypes.func,
  cateringOccasionValue: PropTypes.string,
};

SelectCateringOccasion.defaultProps = {
  handleCateringOccasion: () => {},
  cateringOccasionValue: '',
};

export default SelectCateringOccasion;
