import { Button } from '@cfa/react-components';
import PropTypes from 'prop-types';
import { memo } from 'react';
import styled from 'styled-components';

export const AreYouSure = (props) => {
  const { message, confirm, confirmText, decline, declineText } = props;

  return (
    <StyledAreYouSure>
      <div className="text">{message}</div>
      <div>
        <Button color="secondary" fontSize={2} onClick={decline} variant="outlined">
          {declineText}
        </Button>
        <Button fontSize={2} onClick={confirm} variant="destructive">
          {confirmText}
        </Button>
      </div>
    </StyledAreYouSure>
  );
};

const StyledAreYouSure = styled.div`
  width: calc(100% - 20px);
  padding: 10px;
  min-height: 110px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  & .text {
    font: ${(props) => props.theme.regularBoldFont};
  }

  & button {
    margin: 10px;
  }
`;

AreYouSure.propTypes = {
  message: PropTypes.string.isRequired,
  confirm: PropTypes.func.isRequired,
  confirmText: PropTypes.string.isRequired,
  decline: PropTypes.func.isRequired,
  declineText: PropTypes.string.isRequired,
};

export default memo(AreYouSure);
