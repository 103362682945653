import { TZDateMini } from '@date-fns/tz';
import {
  addDays,
  addHours,
  addMinutes,
  constructNow,
  endOfDay,
  format,
  getMinutes,
  interval,
  isAfter,
  isWithinInterval,
  parse,
  parseISO,
  startOfDay,
} from 'date-fns';
import { contains, drop, dropLast, isEmpty, join, keys, pathOr, pluck, values } from 'ramda';
import type { Nutrition } from '../types/menu';
import type { OrderDate } from '../types/order';

import ooe from '../constants';

export function formatPrice(price?: any) {
  if (Number.isNaN(price) || !price) {
    return '$0.00';
  }
  return `$${parseFloat(price.toString()).toFixed(2)}`;
}

export function formatModifierPrice(price = 0.0) {
  if (price === 0.0) {
    return 'Free';
  }
  return `$${parseFloat(price.toString()).toFixed(2)}`;
}

export function formatItemName(name?: any) {
  if (!name || typeof name !== 'string') {
    return '';
  }
  const abbreviations = ooe.NAME_ABBREVIATIONS;
  return keys(abbreviations).reduce((acc, key) => {
    const replaceWith = abbreviations[key];
    const re = new RegExp(key, 'gi');
    return acc.replace(re, replaceWith);
  }, name);
}

export function formatModifier(mod: { action: string; modifierType: string; name: string }) {
  const { action, modifierType, name } = mod;
  if (!name || typeof name !== 'string') {
    return '';
  }
  if (action === 'REMOVE' || modifierType === 'RECIPE') {
    return `No ${name}`;
  }
  return `${formatItemName(name)}`;
}

export function formatEventSummaryDate(date?: Date) {
  if (!date) {
    return <span className="required">Date*</span>;
  }

  return format(date, ooe.DATE_TIME_FORMAT.eventSummaryDate);
}

export function formatEventSummaryTime(time?: string) {
  if (!time) {
    return <span className="required">Time*</span>;
  }

  return format(parse(time, ooe.DATE_TIME_FORMAT.time, new Date()), ooe.DATE_TIME_FORMAT.eventSummaryTime);
}

export function formatTimePickerTime(time: string) {
  return format(parse(time, ooe.DATE_TIME_FORMAT.time, new Date()), ooe.DATE_TIME_FORMAT.eventSummaryTime);
}

export function formatLongDate(dateTime: string) {
  return format(parseISO(dateTime), ooe.DATE_TIME_FORMAT.longDate);
}

export function formatShortDate(dateTime: string) {
  return format(parseISO(dateTime), ooe.DATE_TIME_FORMAT.shortDate);
}

export function formatReadableDate(dateTime: string) {
  return format(parseISO(dateTime), ooe.DATE_TIME_FORMAT.readableDate);
}

export function formatPaper(paperGoods: boolean) {
  return paperGoods
    ? 'Please include plates, cutlery, napkins and cups. '
    : 'Please do not include plates, cutlery, napkins or cups. ';
}

export function formatPaperGoodsOptions(paperGoods: Record<string, boolean>) {
  if (isEmpty(paperGoods)) {
    return '';
  }
  if (!isEmpty(paperGoods) && Object.values(paperGoods).some((opt) => opt === true)) {
    const paperGoodsArr = Object.keys(paperGoods).filter((k) => paperGoods[k]);
    if (paperGoodsArr.length === 2) {
      paperGoodsArr.splice(1, 0, ' and ');
    } else if (paperGoodsArr.length === 3) {
      paperGoodsArr.splice(1, 0, ', ');
      paperGoodsArr.splice(-1, 0, ' and ');
    } else if (paperGoodsArr.length === 4) {
      paperGoodsArr.splice(1, 0, ', ');
      paperGoodsArr.splice(3, 0, ', ');
      paperGoodsArr.splice(-1, 0, ' and ');
    }
    return `Please include ${paperGoodsArr.join('')}.`;
  }
  return 'Please do not include plates, cutlery, napkins or cups.';
}

export function formatGuests(guestCount?: number) {
  return guestCount ? (
    <div>
      <span className="bold">Guest count: </span>
      {guestCount}
    </div>
  ) : (
    <div>
      <span className="bold">Guest count: </span>
    </div>
  );
}

export function formatCity(city: string) {
  return city ? `${city}, ` : <span className="required">City*</span>;
}

export function formatPOSDeliveryAddress(address: Record<string, string>) {
  return join(' ')(drop(1, dropLast(1, values(address))));
}

export function formatField(field: string, title: string) {
  return field ? `${field}` : <span className="required">{title}*</span>;
}

function validateEmail(email: string) {
  // Email validation RegEx found on SO.
  const re =
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function validatePhone(phone: string) {
  const re = /^[+]?[0-9]{0,3}[\s-]?(\d{3})[\s-](\d{3})[\s-](\d{4})/;
  return re.test(String(phone).toLowerCase());
}

export function formatPhone(phone: string) {
  return validatePhone(phone) ? `${phone}` : <span className="required">Phone Number*</span>;
}

export function formatEmail(email: string) {
  return validateEmail(email) ? `${email}` : <span className="required">Email*</span>;
}

export function capitalizeFirstLetter(input?: string) {
  if (!input) {
    return '';
  }
  const [first, ...rest] = input;
  return first ? first.toUpperCase() + rest.join('') : '';
}

export function titleCase(string = '') {
  if (!string || typeof string !== 'string') {
    return '';
  }
  return string.charAt(0).toUpperCase() + string.toLowerCase().slice(1);
}

export function formatTierName(tier: string) {
  if (tier === 'Prospect') {
    return '';
  }
  return tier;
}

export function isTooLateToCancelOrEdit(promiseTime: string, bypassBusinessRules: boolean, timeWindow = 2) {
  if (bypassBusinessRules) {
    return false;
  }

  const timeOfOrder = parseISO(promiseTime);
  const endOfWindow = addHours(timeOfOrder, timeWindow);
  const currentTime = constructNow(new Date());
  return isAfter(currentTime, endOfWindow);
}

export function generateTimeSlots(dateIsToday: boolean, timeZone: string) {
  const timeSlots = [];
  const now = constructNow(new Date());
  let startTime = startOfDay(now);
  let endTime = endOfDay(now);
  if (dateIsToday) {
    const nowTz = constructNow(TZDateMini.tz(timeZone));
    const remainder = 15 - (getMinutes(nowTz) % 15);
    startTime = addMinutes(nowTz, remainder);
    endTime = endOfDay(nowTz);
  }
  while (startTime <= endTime) {
    timeSlots.push({
      available: true,
      time: format(startTime, ooe.DATE_TIME_FORMAT.time),
    });
    startTime = addMinutes(startTime, 15);
  }
  return timeSlots;
}

export function isValidOrderDate(date: Date, availableDates: OrderDate[], bypassBusinessRules: boolean) {
  if (bypassBusinessRules) {
    const startDate = startOfDay(constructNow(new Date()));
    const endDate = addDays(startDate, 500);
    return isWithinInterval(date, interval(startDate, endDate));
  }
  const businessDates = pluck('businessDate')(availableDates);
  const formattedDate = format(date, ooe.DATE_TIME_FORMAT.date);
  return contains(formattedDate, businessDates);
}

const findVitaminValue = (vitamins: Array<{ name?: string; dailyValue?: number }>, vitaminName: string) =>
  vitamins.find((vit) => vit.name === vitaminName)?.dailyValue || 0;

export function getNutritionValues(nutrition: Nutrition, type: string) {
  const { calories, protein, carbs, sugar, cholesterol, sodium, calcium, iron, fiber, fat, vitamins } =
    nutrition;
  const nutritionalValues = {
    calories: pathOr(0, ['total'], calories),
    totalFat: pathOr(0, ['total', 'amount', 'count'], fat),
    saturatedFat: pathOr(0, ['saturatedFat', 'amount', 'count'], fat),
    transFat: pathOr(0, ['transFat', 'amount', 'count'], fat),
    cholesterol: pathOr(0, ['amount', 'count'], cholesterol),
    sodium: pathOr(0, ['amount', 'count'], sodium),
    carbs: pathOr(0, ['amount', 'count'], carbs),
    fiber: pathOr(0, ['amount', 'count'], fiber),
    sugar: pathOr(0, ['amount', 'count'], sugar),
    protein: pathOr(0, ['amount', 'count'], protein),
    vitaminA: vitamins ? findVitaminValue(vitamins, 'A') : 0,
    vitaminC: vitamins ? findVitaminValue(vitamins, 'C') : 0,
    calcium: pathOr(0, ['dailyValue'], calcium),
    iron: pathOr(0, ['dailyValue'], iron),
  };
  return nutritionalValues[type as keyof typeof nutritionalValues];
}

export const formatRestaurantLocalTime = (timezone: string) =>
  format(constructNow(TZDateMini.tz(timezone)), ooe.DATE_TIME_FORMAT.timeAmPm);
