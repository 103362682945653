import PropTypes from 'prop-types';
import styled from 'styled-components';

import ooeConstants from '../../constants';

function DeviceStatusMessage({ isDeviceOnline, ...remaining }) {
  const statusClass = isDeviceOnline ? 'online' : 'offline';
  const statusMessage = isDeviceOnline ? ooeConstants.ONLINE_MESSAGE : ooeConstants.OFFLINE_MESSAGE;
  return (
    <StyledDeviceStatusMessage {...remaining} className={statusClass}>
      {statusMessage}
    </StyledDeviceStatusMessage>
  );
}

const StyledDeviceStatusMessage = styled.div`
  color: ${(props) => props.theme.colors.secondary};
  font: ${(props) => props.theme.regularBoldFont};
  min-height: 30px;
  text-align: center;
  padding-top: 10px;
  display: none;

  &.online {
    background-color: ${(props) => props.theme.colors.success};
    display: block;
  }

  &.offline {
    background-color: ${(props) => props.theme.colors.error};
    display: block;
  }
`;

DeviceStatusMessage.propTypes = {
  // className: PropTypes.string.isRequired,
  isDeviceOnline: PropTypes.bool,
};

DeviceStatusMessage.defaultProps = {
  isDeviceOnline: true,
};

export default DeviceStatusMessage;
