import Bugsnag, { Breadcrumb, BreadcrumbType, Event } from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { Store } from '@reduxjs/toolkit';
import React from 'react';

import { BugsnagErrorBoundaryPluginHelper } from '../components/BugsnagErrorBoundary/BugsnagErrorBoundary';
import ooeConstants from '../constants';
import { selectCfaOneGuestCFAId } from '../reducers/guest';
import { selectUserCfaGuid, selectUserEmail, selectUserName } from '../reducers/user';

let bugsnagEnabled = false;
let getBugSnagStateData = (_store: any) => ({});

const setBugsnagEnabled = (enabled = false) => {
  bugsnagEnabled = enabled;
};

const initializeGetBugSnagStateData = (initFunction: (store: any) => {}) => {
  getBugSnagStateData = initFunction;
};

const leaveBreadcrumb = (name: string, metadata: any, state: BreadcrumbType = 'manual') => {
  if (!bugsnagEnabled) {
    return false;
  }

  return Bugsnag.leaveBreadcrumb(name, metadata, state);
};

const startSession = () => {
  if (!bugsnagEnabled) {
    return;
  }
  leaveBreadcrumb('Start Session', { message: 'Manually starting new session' }, 'state');
  Bugsnag.startSession();
};

const setMetadata = ({ event, section, info }: { event: Event; section: string; info?: any }) => {
  if (!bugsnagEnabled || !info) {
    return;
  }
  event.addMetadata(section, { ...info });
};

const sendErrorFilter = (event: Event) => {
  const excludeErrorMessagesWithTheseLabels: string[] = [
    // can filter on error text here
    // 'Test Error 2',
  ];

  const mapErrors = (errors: any[]) =>
    errors.map((theError) => {
      const { errorClass, errorMessage, type } = theError;
      return {
        errorClass,
        errorMessage,
        type,
      };
    });

  let sendStatus = true;
  if (!event.errors?.length) {
    leaveBreadcrumb('in sendErrorFilter with no event.errors', {
      message: 'Error not sent to Bugsnag',
    });
    sendStatus = false;
  } else if (event.errors?.every((error) => !error.errorMessage)) {
    leaveBreadcrumb('in sendErrorFilter with event.errors but no error messages', {
      message: 'Error not sent to Bugsnag',
      error: mapErrors(event.errors),
    });
    sendStatus = false;
  } else if (
    event.errors.some((error) =>
      excludeErrorMessagesWithTheseLabels.find((msg) => error.errorMessage?.includes(msg)),
    )
  ) {
    leaveBreadcrumb('in sendErrorFilter, error was filtered', {
      message: 'Error not sent to Bugsnag',
      error: mapErrors(event.errors),
    });
    sendStatus = false;
  }
  return sendStatus;
};

const sendBreadcrumbFilter = (breadcrumb: Breadcrumb) => {
  if (breadcrumb.type === 'user') {
    // Discard clicks with general targetText
    if (breadcrumb.metadata.targetText === '(...)') {
      return false;
    }
    delete breadcrumb.metadata.targetSelector;
  }

  if (breadcrumb.type === 'navigation') {
    if (breadcrumb.metadata?.title) {
      delete breadcrumb.metadata.title;
    }
    if (breadcrumb.metadata?.state) {
      delete breadcrumb.metadata.state;
    }
    if (breadcrumb.metadata?.prevState) {
      delete breadcrumb.metadata.prevState;
    }
  }

  if (breadcrumb.type === 'request') {
    // discard tagging & cookies requests
    const discardedRequests = [
      'mparticle',
      'amplitude',
      'google-analytics',
      'pinterest',
      'doubleclick',
      'cookielaw',
      'taplytics',
    ];
    const { request } = breadcrumb.metadata;
    if (discardedRequests.some((discard) => request.includes(discard))) {
      return false;
    }
  }

  return true;
};

const setupBugsnag = (store: Store) => {
  if (!ooeConstants.BUGSNAG_ENABLED) {
    return;
  }

  setBugsnagEnabled(true);

  Bugsnag.start({
    apiKey: ooeConstants.BUGSNAG_API_KEY,
    autoTrackSessions: false,
    appVersion: ooeConstants.APP_VERSION,
    releaseStage: ooeConstants.BUGSNAG_RELEASE_STAGE,
    maxBreadcrumbs: 100,
    maxEvents: 100,
    plugins: [new BugsnagPluginReact(React)],

    onError: (event) => {
      const state = store.getState();
      const userName = selectUserName(state);
      const userEmail = selectUserEmail(state);
      const userCfaGuid = selectUserCfaGuid(state);
      const guestCfaOneId = selectCfaOneGuestCFAId(state);
      event.setUser(userCfaGuid, userEmail, userName);

      setMetadata({
        event,
        section: 'User',
        info: { guestCfaOneId },
      });

      setMetadata({
        event,
        section: 'State Data',
        info: getBugSnagStateData(store),
      });

      return sendErrorFilter(event);
    },

    onBreadcrumb: (breadcrumb) => sendBreadcrumbFilter(breadcrumb),

    onSession: (session) => {
      const userName = selectUserName(store.getState());
      const userEmail = selectUserEmail(store.getState());
      const userCfaGuid = selectUserCfaGuid(store.getState());
      session.setUser(userCfaGuid, userEmail, userName);
      return true;
    },
  });

  BugsnagErrorBoundaryPluginHelper.BugsnagErrorBoundaryPlugin = Bugsnag.getPlugin(
    'react',
  )?.createErrorBoundary(React) as any;
};

const notifyBugsnag = (errorClass: string, debug?: any) => {
  // this will not be true if Constants.BUGSNAG_ENABLED was false, including in Cypress tests
  if (!bugsnagEnabled) {
    return;
  }

  // use our response if it is already an Error object, otherwise create a new Error object from errorClass
  const error = debug && debug.response instanceof Error ? debug.response : new Error(errorClass);

  Bugsnag.notify(error, (event: Event) => {
    if (!sendErrorFilter(event)) {
      return false;
    }

    if (debug.response?.skipBugsnagReport) {
      // eslint-disable-next-line no-console
      leaveBreadcrumb('Skipping manual Bugsnag notify because debug.response.skipBugsnagReport is true', {
        debugResponse: debug.response,
        error,
        event,
      });
      return false;
    }

    event.context = debug.context;

    error.errorClass = errorClass; // Displays below title in Bugsnag
    error.errorMessage = debug.errorMessage;
    debug.response = {
      ok: debug.response?.ok,
      status: debug.response?.status,
      statusCode: debug.response?.responseData?.statusCode,
      statusText: debug.response?.statusText,
      type: debug.response?.type,
      url: debug.response?.url,
      body: debug.response?.body,
      bodyUsed: debug.response?.bodyUsed,
      redirected: debug.response?.redirected,
      cfaError: debug.response?.responseData?.cfaError,
      cfaErrorCode: debug.response?.responseData?.cfaError?.code,
      devNote: debug.response?.devNote,
    };

    event.addMetadata('errorInfo', { ...debug });
    event.groupingHash = event.groupingHash || event.context || event?.errors?.[0]?.errorMessage;
    return true;
  });
};

export {
  setBugsnagEnabled,
  initializeGetBugSnagStateData,
  leaveBreadcrumb,
  startSession,
  setMetadata,
  setupBugsnag,
  notifyBugsnag,
};
