import { Button } from '@cfa/react-components';
import { Box, Flex, Input } from '@cfacorp/cowponents';
import PropTypes from 'prop-types';
import { memo, useEffect, useState } from 'react';
import styled from 'styled-components';

import ooeConstants from '../../constants';
import { formatPrice } from '../../util/format';
import { ensureValueIsInteger } from '../../util/validate';
import EditCartItem from '../EditCartItem/EditCartItem';
import close from '../Icon/icons/close.svg';
import open from '../Icon/icons/open.svg';
import Modifiers from '../Modifiers/Modifiers';

export const CartItem = (props) => {
  const {
    editMode,
    toggleEditMode,
    item,
    addModifier,
    updateModifierQuantity,
    deleteItem,
    updateSpecialInstructions,
    updateSideItem,
    updateDessertItem,
    addToCart,
    updateQuantity,
    makePromoFree,
    removePromoFree,
    isAdmin,
    isVca,
    showPromoFreeMaxQtyWarning,
  } = props;

  const {
    tag,
    quantity,
    name,
    items,
    price,
    sideItems,
    selectedSide,
    dessertItems,
    selectedDessert,
    id,
    promoFree,
  } = item;

  const [stepperValue, setStepperValue] = useState(quantity);

  const [isEditMode, setIsEditMode] = useState(editMode);

  useEffect(() => {
    setIsEditMode(editMode);
  }, []);

  const handleToggleEditMode = () => {
    setIsEditMode((prev) => !prev);
    toggleEditMode(isEditMode);
  };

  const onChangeHandler = (e) => {
    setStepperValue(e.target.value);
    if (e.target.value === '') {
      return;
    }
    const qty = ensureValueIsInteger(e.target.value);
    updateQuantity(qty);
  };

  const maxQty = promoFree && showPromoFreeMaxQtyWarning ? quantity : 999;

  return (
    <StyledCartItem>
      <div className={isEditMode ? 'edit-mode' : ''}>
        <Flex className="item-box" justifyContent="space-between" key={tag} mb="10px">
          <Box>
            <Input
              data-cy={`quantity-${tag}`}
              data-testid="qty"
              height="39px"
              m="0"
              max={maxQty}
              onBlur={() => setStepperValue(quantity)}
              onChange={onChangeHandler}
              pl="7px"
              textAlign="center"
              type="number"
              value={stepperValue}
              width="50px"
            />
            {!promoFree && <div className="max-warning" />}
            {promoFree && showPromoFreeMaxQtyWarning && <div className="max-promo-free-warning" />}
          </Box>
          <Flex
            alignItems="center"
            className="click-wrapper"
            flex="1"
            onClick={handleToggleEditMode}
            role="presentation"
          >
            <Box data-cy={`name-${tag}`} m="0 20px" width="50%">
              {name}
            </Box>
            <Box data-cy={`${item.tag}-price`} width="30%">
              {formatPrice(price)}
            </Box>
            <Box data-cy={`${item.tag}-promofree`} width="20%">
              {promoFree && (
                <div className="promo-free-indicator" data-testid="promo-free-indicator">
                  Promo Free
                </div>
              )}
            </Box>
            <Box>
              <Button className="edit-button" data-cy={`edit-${tag}`} variant="text">
                {isEditMode ? <img alt="Close Edit" src={close} /> : <img alt="Open Edit" src={open} />}
              </Button>
            </Box>
          </Flex>
        </Flex>
        <Modifiers dessert={selectedDessert} item={item} side={selectedSide} />
        {isEditMode && (
          <EditCartItem
            addModifier={addModifier}
            addToCart={addToCart}
            cartItem={item}
            deleteItem={deleteItem}
            dessertItems={dessertItems}
            editableItems={items}
            freeSauces={item.freeSauces}
            id={id}
            isAdmin={isAdmin}
            isPromoFree={promoFree}
            isVca={isVca}
            itemTag={tag}
            makePromoFree={makePromoFree}
            quantity={quantity}
            removePromoFree={removePromoFree}
            selectedDessert={selectedDessert}
            selectedSide={selectedSide}
            sideItems={sideItems}
            specialInstructions={item.specialInstructions}
            toggleEditMode={handleToggleEditMode}
            updateDessertItem={updateDessertItem}
            updateModifierQuantity={updateModifierQuantity}
            updateSideItem={updateSideItem}
            updateSpecialInstructions={updateSpecialInstructions}
          />
        )}
      </div>
    </StyledCartItem>
  );
};

const StyledCartItem = styled.div`
  border-bottom: 2px dashed ${(props) => props.theme.colors.disabled};
  padding: 20px 0 15px 0;
  font: ${(props) => props.theme.regularTextFont};
  & .item-box {
    position: relative;
  }

  & .max-warning,
  & .max-promo-free-warning {
    position: absolute;
    bottom: -17px;
    left: 0;
  }

  input:invalid + .max-warning:after,
  & .max-promo-free-warning:after {
    content: '999 max';
    font: ${(props) => props.theme.smallBoldFont};
    color: ${(props) => props.theme.colors.error};
    padding-left: 2px;
  }

  & .max-promo-free-warning:after {
    content: 'Total QTY of promo free items must be less than ${ooeConstants.MAX_PROMO_FREE_ITEM_QTY}';
  }

  & .edit-button {
    height: 39px;
    width: 39px;
    min-width: unset;

    :focus {
      outline: none;
    }
  }

  & .edit-button img {
    width: 20px;
  }

  & .special-instructions {
    padding: 7px 10px;
    margin-bottom: 40px;
    max-width: unset;
  }

  & .click-wrapper:hover {
    color: ${(props) => props.theme.colors.accent};
    cursor: pointer;
    font: ${(props) => props.theme.regularBoldFont};
  }
`;

CartItem.propTypes = {
  item: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
      PropTypes.number,
      PropTypes.object,
      PropTypes.array,
    ]),
  ).isRequired,
  editMode: PropTypes.bool,
  toggleEditMode: PropTypes.func,
  deleteItem: PropTypes.func.isRequired,
  addModifier: PropTypes.func.isRequired,
  updateSpecialInstructions: PropTypes.func.isRequired,
  updateQuantity: PropTypes.func,
  updateModifierQuantity: PropTypes.func.isRequired,
  updateSideItem: PropTypes.func.isRequired,
  updateDessertItem: PropTypes.func.isRequired,
  addToCart: PropTypes.func,
  makePromoFree: PropTypes.func,
  removePromoFree: PropTypes.func,
  isAdmin: PropTypes.bool,
  isVca: PropTypes.bool,
  showPromoFreeMaxQtyWarning: PropTypes.bool,
};

CartItem.defaultProps = {
  toggleEditMode: () => {},
  updateQuantity: () => {},
  editMode: false,
  addToCart: () => {},
  makePromoFree: () => {},
  removePromoFree: () => {},
  isAdmin: false,
  isVca: false,
  showPromoFreeMaxQtyWarning: false,
};

export default memo(CartItem);
