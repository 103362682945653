/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import styled from 'styled-components';

import CartLink from './CartLink';
import UserLocation from './UserLocation';

function RightDesktop({
  icon,
  cartItemCount,
  total,
  loading,
  userName,
  locations,
  updateLocation,
  selectedLocation,
  logoutUser,
  toggleBypass,
  shouldBypassBusinessRules,
  isAdminUser,
  locationDropdownDisabled,
  lookupLocation,
  allStoreNamesAndNumbers,
  resetDateFormValues,
  resetTimeFormValues,
}) {
  return (
    <StyledRightDesktop>
      <div className="user-location">
        <div className="user">
          {`${userName} `}(
          <button className="sign-out" onClick={logoutUser}>
            sign out
          </button>
          )
        </div>
        {isAdminUser && (
          <div className="bbr">
            Bypass Business Rules:{' '}
            <button onClick={toggleBypass}>{shouldBypassBusinessRules ? 'On' : 'Off'}</button>
          </div>
        )}
        <UserLocation
          allStoreNamesAndNumbers={allStoreNamesAndNumbers}
          locationDropdownDisabled={locationDropdownDisabled}
          locations={locations}
          lookupLocation={lookupLocation}
          resetDateFormValues={resetDateFormValues}
          resetTimeFormValues={resetTimeFormValues}
          selectedLocation={selectedLocation}
          updateLocation={updateLocation}
        />
      </div>
      <CartLink
        cartItemCount={cartItemCount}
        dataCy="cart-badge"
        icon={icon}
        loading={loading}
        total={total}
      />
    </StyledRightDesktop>
  );
}

const StyledRightDesktop = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-right: 5px;

  & .user-location {
    font: ${(props) => props.theme.smallTextFont};
    color: ${(props) => props.theme.colors.text};
  }
  & .user {
    padding: 0 7px;
  }
  & .bbr {
    padding: 0 7px;
    button {
      cursor: pointer;
      font: ${(props) => props.theme.regularBoldFont};
      color: ${(props) => props.theme.colors.primary};
    }
  }
  & .sign-out {
    color: ${(props) => props.theme.colors.primary};
    border: none;
    background-color: ${(props) => props.theme.colors.background};
    padding: 2px;
  }
  & .sign-out:hover {
    cursor: pointer;
  }
`;

RightDesktop.propTypes = {
  icon: PropTypes.string.isRequired,
  cartItemCount: PropTypes.number.isRequired,
  total: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  userName: PropTypes.string.isRequired,
  locations: PropTypes.arrayOf(PropTypes.object).isRequired,
  updateLocation: PropTypes.func,
  lookupLocation: PropTypes.func,
  selectedLocation: PropTypes.string,
  logoutUser: PropTypes.func.isRequired,
  toggleBypass: PropTypes.func.isRequired,
  shouldBypassBusinessRules: PropTypes.bool.isRequired,
  isAdminUser: PropTypes.bool.isRequired,
  locationDropdownDisabled: PropTypes.bool,
  allStoreNamesAndNumbers: PropTypes.arrayOf(PropTypes.object),
  resetDateFormValues: PropTypes.func,
  resetTimeFormValues: PropTypes.func,
};

RightDesktop.defaultProps = {
  updateLocation: () => {},
  lookupLocation: () => {},
  selectedLocation: '',
  locationDropdownDisabled: false,
  allStoreNamesAndNumbers: [],
};

export default RightDesktop;
