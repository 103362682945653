import { createBrowserHistory } from 'history';
import { PropsWithChildren } from 'react';
import { Router } from 'react-router-dom';

const history = createBrowserHistory();

export const BrowserRouter = ({ children }: PropsWithChildren) => (
  <Router history={history}>{children}</Router>
);

export default history;
