import { type Reducer, type UnknownAction, createSelector } from '@reduxjs/toolkit';
import { constructNow, format, parse, parseISO, startOfDay, subDays } from 'date-fns';
import {
  ascend,
  contains,
  curry,
  forEachObjIndexed,
  indexBy,
  isEmpty,
  path,
  pathOr,
  prop,
  sort,
  values,
} from 'ramda';

import ooeConstants from '../constants';
import type { JSONObject, Nullable, ReducerError } from '../types/util';
import { dashboardErrorMessages } from '../util/customerErrorMessages';
import { formatLongDate, formatPrice, formatShortDate, isTooLateToCancelOrEdit } from '../util/format';
import type { DashboardOrder, DashboardOrderLineItem, DashboardOrderModifier } from '../types/dashboard';
import { selectBypassBusinessRules, types as userTypes } from './user';

export type State = {
  loading: {
    [key: (typeof keys)[keyof typeof keys]]: boolean;
  };
  error: {
    [key: (typeof keys)[keyof typeof keys]]: Nullable<string>;
  };
  orderToViewId: string;
  orders: Record<string, DashboardOrder>;
  startDate: string;
  endDate: string;
  exportExcelOrders: Array<DashboardOrder>;
  allowExcelDownload: boolean;
  errorOccured: boolean;
  loadingExcelAction: boolean;
  specificDays: {
    startDate?: string;
    endDate?: string;
  };
  emailSent: boolean;
};

export const types = {
  GET_ORDERS: '[Dashboard] Upcoming Orders Request',
  ORDERS_SUCCESS: '[Dashboard] Upcoming Orders Success',
  ORDERS_FAILURE: '[Dashboard] Upcoming Orders Failure',
  LOAD_MORE_PAST_ORDERS: '[Dashboard] Load More Past Orders',
  LOAD_MORE_PAST_ORDERS_SUCCESS: '[Dashboard] Load More Past Orders Success',
  LOAD_MORE_PAST_ORDERS_FAILURE: '[Dashboard] Load More Past Orders Failure',
  GET_ORDERS_FOR_SPECIFIC_DAYS: '[Dashboard] Get Orders for Specific Day Request',
  GET_ORDERS_FOR_SPECIFIC_DAYS_SUCCESS: '[Dashboard] Get Orders for Specific Days Success',
  GET_ORDERS_FOR_SPECIFIC_DAYS_FAILURE: '[Dashboard] Get Orders for Specific Days Failure',
  CANCEL_ORDER: '[Dashboard] Cancel Order',
  CANCEL_ORDER_SUCCESS: '[Dashboard] Cancel Order Success',
  CANCEL_ORDER_FAILURE: '[Dashboard] Cancel Order Failure',
  LOOKUP_ORDER_DETAILS: '[Dashboard] Lookup Order Details Request',
  LOOKUP_ORDER_DETAILS_SUCCESS: '[Dashboard] Lookup Order Details Success',
  LOOKUP_ORDER_DETAILS_FAILURE: '[Dashboard] Lookup Order Details Failure',
  LOOKUP_CUSTOMER_DETAILS: '[Dashboard] Lookup Customer Details Request',
  LOOKUP_CUSTOMER_DETAILS_SUCCESS: '[Dashboard] Lookup Customer Details Success',
  LOOKUP_CUSTOMER_DETAILS_FAILURE: '[Dashboard] Lookup Customer Details Failure',
  RESEND_PAYMENT_EMAIL: '[Dashboard] Resend Payment Email',
  RESEND_PAYMENT_EMAIL_SUCCESS: '[Dashboard] Resend Payment Email Success',
  RESEND_PAYMENT_EMAIL_FAILURE: '[Dashboard] Resend Payment Email Failure',
  GET_DATA_FOR_EXCEL_EXTRACTION: '[Dashboard] Get Data for Excel Extraction',
  GET_DATA_FOR_EXCEL_EXTRACTION_SUCCESS: '[Dashboard] Get Data for Excel Extraction Success',
  GET_DATA_FOR_EXCEL_EXTRACTION_FAILURE: '[Dashboard] Get Data for Excel Extraction Failure',
  DOWNLOAD_EXCEL: '[Dashboard] Download Excel',
};

export const keys = {
  ORDERS: 'orders',
  CANCEL_ORDER: 'cancelOrder',
  ORDER_DETAILS: 'orderDetails',
  CUSTOMER_DETAILS: 'customerDetails',
  RESEND_EMAIL: 'resendEmail',
  LOAD_MORE_PAST_ORDERS: 'loadMorePastOrders',
  EXCEL_EXTRACTION: 'excelExtraction',
};

export const actions = {
  getOrders: () => ({ type: types.GET_ORDERS, key: keys.ORDERS, startDate: undefined, endDate: undefined }),
  getOrdersForSpecificDays: (startDate: string, endDate: string) => ({
    type: types.GET_ORDERS_FOR_SPECIFIC_DAYS,
    key: keys.ORDERS,
    startDate,
    endDate,
  }),
  ordersForSpecificDaysSuccess: (orders: Array<DashboardOrder>) => ({
    type: types.GET_ORDERS_FOR_SPECIFIC_DAYS_SUCCESS,
    key: keys.ORDERS,
    orders,
  }),
  ordersForSpecificDaysFailure: (error: ReducerError) => ({
    type: types.GET_ORDERS_FOR_SPECIFIC_DAYS_FAILURE,
    key: keys.ORDERS,
    error,
  }),
  ordersSuccess: (orders: Array<DashboardOrder>) => ({
    type: types.ORDERS_SUCCESS,
    key: keys.ORDERS,
    orders,
    startDate: undefined,
  }),
  ordersFailure: (error: ReducerError) => ({
    type: types.ORDERS_FAILURE,
    key: keys.ORDERS,
    error,
  }),
  loadMorePastOrders: () => ({
    type: types.LOAD_MORE_PAST_ORDERS,
    key: keys.LOAD_MORE_PAST_ORDERS,
  }),
  loadMorePastOrdersSuccess: curry((startDate: string, orders: Array<DashboardOrder>) => ({
    type: types.LOAD_MORE_PAST_ORDERS_SUCCESS,
    key: keys.LOAD_MORE_PAST_ORDERS,
    orders,
    startDate,
  })),
  loadMorePastOrdersFailure: (error: ReducerError) => ({
    type: types.LOAD_MORE_PAST_ORDERS_FAILURE,
    key: keys.LOAD_MORE_PAST_ORDERS,
    error,
  }),
  cancelOrder: (orderId: string, cfaId: string) => ({
    type: types.CANCEL_ORDER,
    key: keys.CANCEL_ORDER,
    orderId,
    cfaId,
  }),
  cancelOrderSuccess: (orderId: string) => ({
    type: types.CANCEL_ORDER_SUCCESS,
    key: keys.CANCEL_ORDER,
    orderId,
  }),
  cancelOrderFailure: (error: ReducerError) => ({
    type: types.CANCEL_ORDER_FAILURE,
    key: keys.CANCEL_ORDER,
    error,
  }),
  lookupOrderDetails: (orderId: string, cfaId: string) => ({
    type: types.LOOKUP_ORDER_DETAILS,
    key: keys.ORDER_DETAILS,
    orderId,
    cfaId,
  }),
  lookupOrderDetailsSuccess: (order: DashboardOrder) => ({
    type: types.LOOKUP_ORDER_DETAILS_SUCCESS,
    key: keys.ORDER_DETAILS,
    order,
  }),
  lookupOrderDetailsFailure: (error: ReducerError) => ({
    type: types.LOOKUP_ORDER_DETAILS_FAILURE,
    key: keys.ORDER_DETAILS,
    error,
  }),
  lookupCustomerDetails: (orderId: string, cfaId: string) => ({
    type: types.LOOKUP_CUSTOMER_DETAILS,
    key: keys.CUSTOMER_DETAILS,
    orderId,
    cfaId,
  }),
  lookupCustomerDetailsSuccess: (customerDetails: {
    phone?: Nullable<string>;
    email?: Nullable<string>;
  }) => ({
    type: types.LOOKUP_CUSTOMER_DETAILS_SUCCESS,
    key: keys.CUSTOMER_DETAILS,
    customerDetails,
  }),
  lookupCustomerDetailsFailure: (error: ReducerError) => ({
    type: types.LOOKUP_CUSTOMER_DETAILS_FAILURE,
    key: keys.CUSTOMER_DETAILS,
    error,
  }),
  resendPaymentEmail: (orderId: string, cfaId: string) => ({
    type: types.RESEND_PAYMENT_EMAIL,
    key: keys.RESEND_EMAIL,
    orderId,
    cfaId,
  }),
  resendPaymentEmailSuccess: () => ({
    type: types.RESEND_PAYMENT_EMAIL_SUCCESS,
  }),
  resendPaymentEmailFailure: (error: ReducerError) => ({
    error,
    type: types.RESEND_PAYMENT_EMAIL_FAILURE,
  }),
  getDataForExcelExtraction: (order: DashboardOrder) => ({
    type: types.GET_DATA_FOR_EXCEL_EXTRACTION,
    key: keys.EXCEL_EXTRACTION,
    order,
  }),
  getDataForExcelExtractionSuccess: curry((order: DashboardOrder, response: JSONObject) => ({
    type: types.GET_DATA_FOR_EXCEL_EXTRACTION_SUCCESS,
    key: keys.EXCEL_EXTRACTION,
    order,
    response,
  })),
  getDataForExcelExtractionFailure: (error: ReducerError) => ({
    type: types.GET_DATA_FOR_EXCEL_EXTRACTION_FAILURE,
    key: keys.EXCEL_EXTRACTION,
    error,
  }),
  downloadExcel: () => ({ type: types.DOWNLOAD_EXCEL }),
};

const initialState: State = {
  loading: {
    orders: false,
    customerDetails: false,
    cancelOrder: false,
    resendEmail: false,
    orderDetails: false,
    excelExtraction: false,
  },
  error: {
    orders: null,
    customerDetails: null,
    orderDetails: null,
    cancelOrder: null,
    resendEmail: null,
    excelExtraction: null,
  },
  orderToViewId: '',
  orders: {},
  startDate: '',
  endDate: '',
  exportExcelOrders: [],
  allowExcelDownload: false,
  errorOccured: false,
  loadingExcelAction: false,
  specificDays: {},
  emailSent: false,
};

const reducer: Reducer<State, UnknownAction> = (state = initialState, action) => {
  switch (action.type) {
    case userTypes.UPDATE_USER_LOCATION:
    case userTypes.GET_USER_LOCATIONS_SUCCESS: {
      return {
        ...state,
        loading: { ...state.loading, orders: true },
        error: { ...state.error, orders: null },
      };
    }

    case types.GET_ORDERS:
    case types.GET_ORDERS_FOR_SPECIFIC_DAYS: {
      const { key, startDate, endDate } = action as
        | ReturnType<(typeof actions)['getOrders']>
        | ReturnType<(typeof actions)['getOrdersForSpecificDays']>;
      return {
        ...state,
        loading: { ...state.loading, [key]: true },
        error: { ...state.error, [key]: null },
        exportExcelOrders: [],
        errorOccured: false,
        specificDays: {
          startDate,
          endDate,
        },
      };
    }
    case types.GET_DATA_FOR_EXCEL_EXTRACTION: {
      const { key } = action as ReturnType<(typeof actions)['getDataForExcelExtraction']>;
      return {
        ...state,
        loading: { ...state.loading, [key]: true },
        error: { ...state.error, [key]: null },
        exportExcelOrders: [],
        errorOccured: false,
        loadingExcelAction: true,
      };
    }

    case types.LOOKUP_ORDER_DETAILS:
    case types.LOOKUP_CUSTOMER_DETAILS:
    case types.RESEND_PAYMENT_EMAIL:
    case types.CANCEL_ORDER: {
      const { key, orderId } = action as
        | ReturnType<(typeof actions)['lookupOrderDetails']>
        | ReturnType<(typeof actions)['lookupCustomerDetails']>
        | ReturnType<(typeof actions)['resendPaymentEmail']>
        | ReturnType<(typeof actions)['cancelOrder']>;
      return {
        ...state,
        orderToViewId: orderId,
        loading: { ...state.loading, [key]: true },
        error: { ...state.error, [key]: null },
      };
    }
    case types.LOAD_MORE_PAST_ORDERS: {
      const { key } = action as ReturnType<(typeof actions)['loadMorePastOrders']>;
      const { endDate } = state;
      return {
        ...state,
        loading: {
          ...state.loading,
          [key]: true,
        },
        error: { ...state.error, [key]: null },
        endDate,
      };
    }
    case types.ORDERS_SUCCESS:
    case types.LOAD_MORE_PAST_ORDERS_SUCCESS: {
      const { orders, startDate, key, type } = action as
        | ReturnType<(typeof actions)['ordersSuccess']>
        | ReturnType<(typeof actions)['loadMorePastOrdersSuccess']>;
      const filteredOrders = orders.filter(
        (order) =>
          order.destination !== 'On demand' &&
          order.destination !== 'Curbside' &&
          order.destination !== 'Cfa delivery',
      );
      let ordersToShow;
      let startedDate = startDate || format(constructNow(new Date()), ooeConstants.DATE_TIME_FORMAT.date);
      const newOrders = indexBy(prop('id'), filteredOrders);
      const isInitalLoad = type === types.ORDERS_SUCCESS;

      //on initial load the one past day is already loaded, this statement is to load one more day on first click
      if (isInitalLoad) {
        startedDate = format(
          subDays(startOfDay(parse(startedDate, ooeConstants.DATE_TIME_FORMAT.date, new Date())), 1),
          ooeConstants.DATE_TIME_FORMAT.date,
        );
      }
      if (isInitalLoad) {
        ordersToShow = newOrders;
      } else {
        ordersToShow = { ...state.orders, ...newOrders };
      }

      let dateRange;
      const { specificDays } = state;
      if (specificDays?.startDate) {
        dateRange = {
          specificDays: {
            ...specificDays,
            startDate: startedDate,
          },
        };
      } else {
        dateRange = {
          endDate: startedDate,
        };
      }

      return {
        ...state,
        loading: { ...state.loading, [key]: false },
        error: { ...state.error, [key]: null },
        orders: ordersToShow,
        ...dateRange,
      };
    }

    case types.GET_ORDERS_FOR_SPECIFIC_DAYS_SUCCESS: {
      const { orders, key } = action as ReturnType<(typeof actions)['ordersForSpecificDaysSuccess']>;
      const filteredOrders = orders.filter(
        (order) =>
          order.destination !== 'On demand' &&
          order.destination !== 'Curbside' &&
          order.destination !== 'Cfa delivery',
      );
      const newOrders = indexBy(prop('id'), filteredOrders);

      return {
        ...state,
        loading: { ...state.loading, [key]: false },
        error: { ...state.error, [key]: null },
        orders: newOrders,
      };
    }

    case types.LOOKUP_ORDER_DETAILS_SUCCESS: {
      const { order } = action as ReturnType<(typeof actions)['lookupOrderDetailsSuccess']>;
      const { orderToViewId } = state;
      return {
        ...state,
        emailSent: false,
        loading: { ...state.loading, orderDetails: false },
        error: {
          ...state.error,
          orderDetails: null,
          cancelOrder: null,
          resendEmail: null,
        },
        // Add additional order details to existing order object
        orders: {
          ...state.orders,
          [orderToViewId]: {
            ...state.orders[orderToViewId],
            ...order,
          },
        },
      };
    }

    case types.LOOKUP_CUSTOMER_DETAILS_SUCCESS: {
      const { customerDetails } = action as ReturnType<(typeof actions)['lookupCustomerDetailsSuccess']>;
      const { email, phone } = customerDetails;
      const { orderToViewId } = state;
      let errorMessage = null;
      if ((!email || email.length === 0) && (!phone || phone.length === 0)) {
        errorMessage = dashboardErrorMessages.DETAILS_ERROR;
      } else if (!phone || phone.length === 0) {
        errorMessage = dashboardErrorMessages.NO_PHONE;
      } else if (!email || email.length === 0) {
        errorMessage = dashboardErrorMessages.NO_EMAIL;
      }
      return {
        ...state,
        loading: { ...state.loading, customerDetails: false },
        error: {
          ...state.error,
          customerDetails: errorMessage,
          orderDetails: null,
          cancelOrder: null,
          resendEmail: null,
        },
        orders: {
          ...state.orders,
          [orderToViewId]: {
            ...state.orders[orderToViewId],
            ...customerDetails,
          },
        },
      };
    }

    case types.GET_DATA_FOR_EXCEL_EXTRACTION_SUCCESS: {
      const { response, order, key } = action as ReturnType<
        (typeof actions)['getDataForExcelExtractionSuccess']
      >;
      return {
        ...state,
        loading: { ...state.loading, [key]: false },
        error: {
          ...state.error,
          [key]: null,
        },
        exportExcelOrders: [...state.exportExcelOrders, { ...response, ...order }],
        allowExcelDownload: true,
      };
    }

    case types.CANCEL_ORDER_SUCCESS: {
      const { orderId } = action as ReturnType<(typeof actions)['cancelOrderSuccess']>;
      return {
        ...state,
        loading: { ...state.loading, cancelOrder: false },
        error: { ...state.loading, cancelOrder: null },
        // Manually add timestamp and update status so the detail view updates immediately
        orders: {
          ...state.orders,
          [orderId]: {
            ...state.orders[orderId],
            status: 'Cancelled',
            displayStatus: { statusName: 'Cancelled', statusIcon: 'error' },
            timestamps: {
              ...state.orders[orderId].timestamps,
              cancel: format(constructNow(new Date()), ooeConstants.DATE_TIME_FORMAT.dateTime),
            },
          },
        },
      };
    }

    case types.RESEND_PAYMENT_EMAIL_SUCCESS: {
      const { orderToViewId } = state;
      return {
        ...state,
        emailSent: true,
        loading: { ...state.loading, resendEmail: false },
        error: { ...state.error, resendEmail: null },
        // Manually add timestamp so the orderHistory updates immediately
        orders: {
          ...state.orders,
          [orderToViewId]: {
            ...state.orders[orderToViewId],
            timestamps: {
              ...state.orders[orderToViewId].timestamps,
              paymentPending: format(constructNow(new Date()), ooeConstants.DATE_TIME_FORMAT.dateTime),
            },
          },
        },
      };
    }

    case types.LOOKUP_ORDER_DETAILS_FAILURE: {
      return {
        ...state,
        emailSent: false,
        loading: { ...state.loading, orderDetails: false },
        error: {
          ...state.error,
          orderDetails: dashboardErrorMessages.ORDER_ERROR,
        },
      };
    }

    case types.LOOKUP_CUSTOMER_DETAILS_FAILURE: {
      return {
        ...state,
        loading: { ...state.loading, customerDetails: false },
        error: {
          ...state.error,
          customerDetails: dashboardErrorMessages.DETAILS_ERROR,
        },
      };
    }

    case types.CANCEL_ORDER_FAILURE: {
      return {
        ...state,
        loading: { ...state.loading, cancelOrder: false },
        error: {
          ...state.error,
          cancelOrder: dashboardErrorMessages.CANCEL_ERROR,
        },
      };
    }

    case types.RESEND_PAYMENT_EMAIL_FAILURE: {
      return {
        ...state,
        emailSent: false,
        loading: { ...state.loading, resendEmail: false },
        error: {
          ...state.error,
          resendEmail: dashboardErrorMessages.RESEND_EMAIL_ERROR,
        },
      };
    }

    case types.GET_DATA_FOR_EXCEL_EXTRACTION_FAILURE: {
      return {
        ...state,
        loading: { ...state.loading, excelExtraction: false },
        error: {
          ...state.error,
          excelExtraction: dashboardErrorMessages.GET_EXCEL_DATA_ERROR,
        },
        allowExcelDownload: false,
        //if one or more of api calls fall this will be saved in state and error message will be shown
        errorOccured: true,
        loadingExcelAction: false,
      };
    }

    case types.ORDERS_FAILURE:
    case types.GET_ORDERS_FOR_SPECIFIC_DAYS_FAILURE:
    case types.LOAD_MORE_PAST_ORDERS_FAILURE: {
      const { error, key } = action as
        | ReturnType<(typeof actions)['ordersFailure']>
        | ReturnType<(typeof actions)['ordersForSpecificDaysFailure']>
        | ReturnType<(typeof actions)['loadMorePastOrdersFailure']>;
      return {
        ...state,
        loading: { ...state.loading, [key]: false },
        error: { ...state.error, [key]: error.toString() },
      };
    }
    //this is added because if isallowExcelDownload is not sved in state and we come back on page, excel will be download again
    case types.DOWNLOAD_EXCEL: {
      return {
        ...state,
        allowExcelDownload: false,
        loadingExcelAction: false,
      };
    }

    default:
      return state;
  }
};

export default reducer;

// Selector helper functions
export function getOrderStatus(status: keyof typeof ooeConstants.GET_STATUS, paymentType: string) {
  let statusName = ooeConstants.GET_STATUS[status];
  let statusIcon = 'success';
  if (statusName === ooeConstants.GET_STATUS.PaymentPending) {
    statusIcon = 'warning';
  } else if (contains(status, ooeConstants.CANCELLED) || status === ooeConstants.ERROR) {
    statusIcon = 'error';
  } else if (status === 'Submit' && paymentType === 'TO_BE_COLLECTED') {
    statusName = ooeConstants.GET_STATUS.PayLater;
  } else if (
    statusName === ooeConstants.GET_STATUS.Finalized ||
    statusName === ooeConstants.GET_STATUS.Stored
  ) {
    statusIcon = 'restaurant';
  }
  return {
    statusName,
    statusIcon,
  };
}

function formatDisplayFields(order: DashboardOrder) {
  return {
    ...order,
    customerName: `${order.givenName} ${order.familyName}`,
    total: order.totalAmount === undefined ? order.subTotalAmount + order.taxAmount : order.totalAmount,
    displayStatus: getOrderStatus(
      order.status as keyof typeof ooeConstants.GET_STATUS,
      pathOr('', ['payment', 'paymentType'], order),
    ),
    displayLongDate: formatLongDate(order.promiseDateTime),
    displaySubtotal: formatPrice(order.subTotalAmount),
    displayPrice:
      order.totalAmount === undefined
        ? formatPrice(order.subTotalAmount + order.taxAmount)
        : formatPrice(order.totalAmount),
    displayClient:
      ooeConstants.GET_CLIENT[order.clientId as keyof typeof ooeConstants.GET_CLIENT] || 'Unknown',
    displayTaxAmount: formatPrice(order.taxAmount),
  };
}

function calculateModifierQuantity(mod: DashboardOrderModifier, lineItemQuantity: number) {
  const { quantity, itemTag } = mod;
  const toggleable = contains(itemTag, ooeConstants.TOGGLEABLE_ITEM_TAGS);
  // Use line item quantity for toggleable modifiers
  if (toggleable) {
    return lineItemQuantity;
  }
  // Use item quantity for all other modifiers
  return quantity;
}

function calculateModifierPrice(mod: DashboardOrderModifier, lineItemQuantity: number) {
  const { retailPrice, priceAdjustment, itemTag } = mod;
  const isSauce = contains(itemTag, ooeConstants.SAUCES);
  // Use sauce price for sauce modifiers
  if (isSauce) {
    return priceAdjustment;
  }
  // Multiply price by line item quantity for all other items
  return retailPrice * lineItemQuantity;
}

function findComboItem(lineItem: DashboardOrderLineItem, itemTags: string[]) {
  const { quantity } = lineItem;
  if (lineItem.comboItems) {
    // Check if combo items contains a side
    const comboItem: (typeof lineItem.comboItems)[number] = pathOr(
      {},
      [0],
      lineItem.comboItems.filter((item) => contains(item.itemTag, itemTags)),
    );
    // If it does, return the side item
    if (!isEmpty(comboItem)) {
      return {
        ...comboItem,
        price: comboItem.priceAdjustment * quantity,
        displayQuantity: quantity,
      };
    }
  }
  return undefined;
}

function findSide(lineItem: DashboardOrderLineItem) {
  return findComboItem(lineItem, ooeConstants.SIDES);
}

function findDessert(lineItem: DashboardOrderLineItem) {
  return findComboItem(lineItem, ooeConstants.DESSERTS);
}

function findModifiers(lineItem: DashboardOrderLineItem) {
  const { quantity } = lineItem;
  if (lineItem.modifiers) {
    return lineItem.modifiers.map((mod) => ({
      ...mod,
      tag: mod.itemTag,
      price: calculateModifierPrice(mod, quantity),
      displayQuantity: calculateModifierQuantity(mod, quantity),
    }));
  }
  return [];
}

function findComboModifiers(lineItem: DashboardOrderLineItem) {
  const { quantity } = lineItem;
  const comboMods: Array<
    DashboardOrderLineItem & { tag: string | undefined; price: number; displayQuantity: number }
  > = [];
  if (lineItem.comboItems) {
    lineItem.comboItems.forEach((comboItem) => {
      if (comboItem.modifiers) {
        comboItem.modifiers.forEach((comboMod) => {
          comboMods.push({
            ...comboMod,
            tag: comboMod.itemTag,
            price: comboMod.priceAdjustment * quantity,
            displayQuantity: calculateModifierQuantity(comboMod, quantity),
          });
        });
      }
    });
  }
  return comboMods;
}

function findPOSModifiers(lineItem: DashboardOrderLineItem) {
  if (lineItem.subItems) {
    return lineItem.subItems.map((subItem) => ({
      ...subItem,
      tag: subItem.itemTag,
      price: subItem.retailPrice,
      displayQuantity: subItem.quantity,
    }));
  }
  return [];
}

function mapOrderDetails(orderDetails: DashboardOrder) {
  let mappedLineItems;
  if (orderDetails.lineItems) {
    mappedLineItems = orderDetails.lineItems.map((lineItem, idx) => ({
      ...lineItem,
      tag: lineItem.itemTag,
      key: `${lineItem.itemTag}-${idx}`,
      price: lineItem.retailPrice * lineItem.quantity,
      selectedSide: findSide(lineItem),
      selectedDessert: findDessert(lineItem),
      modifiers: findPOSModifiers(lineItem)
        .concat(findComboModifiers(lineItem))
        .concat(findModifiers(lineItem)),
    }));
  }
  const additionalFields = formatDisplayFields(orderDetails);
  return {
    ...orderDetails,
    ...additionalFields,
    lineItems: mappedLineItems,
  };
}

// Selectors
export const selectDashboard = (state: { dashboard: State }) => state.dashboard;

// Dashboard Table selectors
export const selectOrders = createSelector(selectDashboard, (dashboard) =>
  values(dashboard.orders).map((order) => formatDisplayFields(order)),
);

// Detail View Selectors
export const selectOrderToView = createSelector(selectDashboard, (dashboard) => {
  if (dashboard.orders[dashboard.orderToViewId]) {
    return mapOrderDetails(dashboard.orders[dashboard.orderToViewId]);
  }

  return {} as ReturnType<typeof mapOrderDetails>;
});
export const selectIsPOSOrder = createSelector(
  selectOrderToView,
  (order) => pathOr('', ['clientId'], order) === 'POS',
);
export const selectIsErrorStatus = createSelector(
  selectOrderToView,
  (order) => order.status === ooeConstants.ERROR,
);

// Detail View action selectors
export const selectPaymentEmailResent = createSelector(selectDashboard, (dashboard) => dashboard.emailSent);
export const selectTooLateToCancelOrEdit = createSelector(
  selectBypassBusinessRules,
  selectOrderToView,
  (bypassBusinessRules, order) => {
    const { status, promiseDateTime } = order;
    const cancelled = contains(status, ooeConstants.CANCELLED);
    const tendered = status === ooeConstants.FINALIZED;
    const tooLate = promiseDateTime && isTooLateToCancelOrEdit(promiseDateTime, bypassBusinessRules);
    return (tooLate && !cancelled) || tendered;
  },
);

// Order history selectors
export const selectOrderHistory = createSelector(selectOrderToView, (order) => {
  const orderHistory: Array<{ timestamp: string; event: string; sortableValue: number }> = [];
  let timestamps: DashboardOrder['timestamps'] = pathOr({}, ['timestamps'], order);
  if (order.fullError) {
    const errorTimestamp = { error: path(['fullError', 'createdAt'], order) };
    timestamps = { ...timestamps, ...errorTimestamp };
  }

  const buildOrderHistory = (value: string, key: keyof ReturnType<typeof ooeConstants.GET_EVENT_MESSAGE>) =>
    orderHistory.push({
      timestamp: formatShortDate(value),
      event: ooeConstants.GET_EVENT_MESSAGE(order)[key],
      sortableValue: parseISO(value).valueOf(),
    });

  forEachObjIndexed(buildOrderHistory, timestamps);
  const byTime = ascend(prop('sortableValue'));
  return sort(byTime, orderHistory);
});

export const selectLastEndDate = createSelector(selectDashboard, (dashboard) => dashboard?.endDate);

// Loading selectors
export const selectOrdersAreLoading = createSelector(
  selectDashboard,
  (dashboard) => dashboard.loading.orders,
);
export const selectLookupOrderIsLoading = createSelector(
  selectDashboard,
  (dashboard) => dashboard.loading.orderDetails || dashboard.loading.customerDetails,
);
export const selectDashboardActionLoading = createSelector(
  selectDashboard,
  (dashboard) => dashboard.loading.cancelOrder || dashboard.loading.resendEmail,
);

export const selectDashboardPastOrderLoading = createSelector(
  selectDashboard,
  (dashboard) => dashboard?.loading?.loadMorePastOrders,
);

// Error selectors
export const selectDashboardErrors = createSelector(selectDashboard, (dashboard) => dashboard.error);
export const selectOrdersError = createSelector(selectDashboardErrors, (errors) => errors.orders);
export const selectExelExtractionError = createSelector(
  selectDashboardErrors,
  (errors) => errors.excelExtraction,
);
export const selectDetailViewErrors = createSelector(
  selectTooLateToCancelOrEdit,
  selectIsErrorStatus,
  selectDashboardErrors,
  selectIsPOSOrder,
  (tooLate, errorStatus, errors, isPOSOrder) => {
    let detailViewErrors = Object.values(errors);
    if (tooLate) {
      detailViewErrors.push(dashboardErrorMessages.TOO_LATE_TO_CANCEL);
    }
    if (errorStatus) {
      detailViewErrors.push(dashboardErrorMessages.ERROR_STATUS);
    }
    if (isPOSOrder) {
      detailViewErrors = [errors.orderDetails];
      detailViewErrors.push(dashboardErrorMessages.POS_ORDER);
    }
    return detailViewErrors;
  },
);
export const selectExtractOrdersDataLoading = createSelector(
  selectDashboard,
  (dashboard) => dashboard?.loadingExcelAction,
);

export const selectExtractOrdersData = createSelector(
  selectDashboard,
  (dashboard) => dashboard?.exportExcelOrders,
);

export const selectAllowExcelDownload = createSelector(
  selectDashboard,
  (dashboard) => dashboard?.allowExcelDownload,
);

export const selectExportEntireDaysExcelError = createSelector(
  selectDashboard,
  (dashboard) => dashboard?.errorOccured && dashboardErrorMessages.GET_EXCEL_DATA_ERROR,
);

export const selectSpecificDays = createSelector(selectDashboard, (dashboard) => ({
  ...dashboard?.specificDays,
}));
