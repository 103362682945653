import PropTypes from 'prop-types';
import styled from 'styled-components';

function Overlay(props) {
  const { className, goBack } = props;

  return (
    <div className={className} onClick={() => goBack()} onKeyDown={() => goBack()} role="presentation" />
  );
}

const StyledOverlay = styled(Overlay)`
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #61616175;
  z-index: 2000;
`;

Overlay.propTypes = {
  className: PropTypes.string.isRequired,
  goBack: PropTypes.func.isRequired,
};

export default StyledOverlay;
