import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import type { FeatureFlags } from '../types/featureFlags';

import ooeConstants from '../constants';
import request from '../util/requestFactory';

let featureFlags: Record<string, boolean> = {};

const initialize = () => {
  featureFlags = {};
  return getFeatureFlags()
    .execute()
    .pipe(
      switchMap((res) => {
        featureFlags = res.allFlags;
        return of(null);
      }),
    );
};

const variation = (featureFlag: string) => !!featureFlags[featureFlag];

const getFeatureFlags = () => {
  const url = `${ooeConstants.URL.CMT_CONFIG}/1.0/feature-flags`;
  return request<FeatureFlags>({
    url,
    method: 'GET',
    bugsnag: {
      errorClass: ooeConstants.BUGSNAG_ERRORCLASS_CMT_CONFIG,
      context: 'Get Feature Flags From Config',
    },
  });
};

export { getFeatureFlags, initialize, variation };
