export const isRedirectToHttpsNecessary = (location: Location) =>
  location.hostname !== 'localhost' && location.protocol !== 'https:';

const redirectToHttpsIfNecessary = ({
  location,
  redirectToUrl,
}: {
  location: Location;
  redirectToUrl: (url: string) => void;
}) => {
  if (isRedirectToHttpsNecessary(location)) {
    const httpsUrl = `https:${location.href.substring(location.protocol.length)}`;
    redirectToUrl(httpsUrl);
  }
};

export default redirectToHttpsIfNecessary;
