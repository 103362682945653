import { ofType } from 'redux-observable';
import { fromEvent } from 'rxjs';
import { debounceTime, mapTo } from 'rxjs/operators';

import ooeConstants from '../constants';
import { actions, types } from '../reducers/device';

export const ListenForOnlineStatus = () => fromEvent(window, 'online').pipe(mapTo(actions.deviceOnline()));

export const TurnOffOnlineMessage = ($actions) =>
  $actions.pipe(
    ofType(types.DEVICE_ONLINE, types.DEVICE_OFFLINE),
    debounceTime(ooeConstants.ONLINE_MESSAGE_TIMEOUT),
    ofType(types.DEVICE_ONLINE),
    mapTo(actions.hideMessage()),
  );

export const ListenForOfflineStatus = () => fromEvent(window, 'offline').pipe(mapTo(actions.deviceOffline()));

export default [ListenForOnlineStatus, TurnOffOnlineMessage, ListenForOfflineStatus];
