import { TZDateMini } from '@date-fns/tz';
import { addDays, constructNow, format, isSunday, subDays } from 'date-fns';
import { map, pathOr, pick } from 'ramda';
import { AnalyticsApiOrderResponse } from 'src/types/dashboard';
import { Customer } from 'src/types/guest';
import Order, { POSCateringOrder } from 'src/types/order';

import ooeConstants from '../constants';
import { capitalizeFirstLetter, formatPOSDeliveryAddress, formatTierName, titleCase } from '../util/format';
import normalizePhone from '../util/normalizePhone';
import requestFactory from '../util/requestFactory';

function mapOrders(order: AnalyticsApiOrderResponse) {
  const {
    orderId,
    locationNumber,
    status,
    subtotal,
    tax,
    accountNumber,
    expirationMonth,
    expirationYear,
    paymentType,
    destination,
    currentTierName,
    promiseTime,
    clientId,
    givenName,
    lastName,
    customerId,
    transId,
    phoneNumber,
  } = order;
  return {
    id: orderId || transId,
    cfaId: customerId,
    transId,
    locationNumber,
    status,
    subTotalAmount: subtotal,
    taxAmount: tax,
    payment: {
      paymentType,
      accountNumber,
      expirationYear,
      expirationMonth,
    },
    destination: titleCase(destination),
    cfaLoyalty: formatTierName(currentTierName),
    promiseDateTime: format(new TZDateMini(promiseTime, '+00:00'), ooeConstants.DATE_TIME_FORMAT.dateTime),
    time: format(new TZDateMini(promiseTime, '+00:00'), ooeConstants.DATE_TIME_FORMAT.time),
    utcTime: promiseTime,
    clientId,
    givenName: capitalizeFirstLetter(givenName),
    familyName: capitalizeFirstLetter(lastName),
    phoneNumber,
  };
}

function mapCustomerDetails(customer: Customer, orderId: string) {
  return {
    id: orderId,
    email: pathOr('', ['customer', 'emailAddress', 'value'], customer),
    phone: normalizePhone(pathOr('', ['customer', 'phoneNumber', 'value', 'base'], customer)),
    givenName: pathOr('', ['customer', 'name', 'givenName'], customer),
    familyName: pathOr('', ['customer', 'name', 'familyName'], customer),
  };
}

function mapDetailViewOrder(order: Order) {
  return pick(
    [
      'id',
      'lineItems',
      'specialInstructions',
      'timestamps',
      'deliveryAddress',
      'companyName',
      'paperGoods',
      'payment',
      'guestCount',
      'fullError',
      'phone',
      'totalAmount',
      'deliveryTip',
      'cateringReason',
      'secondaryContact',
      'subTotalAmount',
      'taxAmount',
    ],
    order,
  );
}

function mapPOSOrderDetail(order: POSCateringOrder) {
  const picked = mapDetailViewOrder(order);
  const address = formatPOSDeliveryAddress(order.deliveryAddress || {});
  const totalAmount = order?.tranTotal;
  return { ...picked, deliveryAddress: address, totalAmount };
}

function getOrdersFromApi(locationId: string, apiKey: string, startDate: string, endDate: string) {
  const baseUrl = ooeConstants.URL.ANALYTICS;
  const url = `${baseUrl}/catering/2.0/${locationId}?startDate=${startDate}&endDate=${endDate}`;
  const mapper = map(mapOrders);
  return requestFactory({
    url,
    method: 'GET',
    auth: {
      type: 'JWTBearer',
      apiKey,
    },
    mapper,
    bugsnag: {
      breadcrumb: `Get Catering Orders Between ${startDate} and ${endDate}`,
      errorClass: ooeConstants.BUGSNAG_ERRORCLASS_ANALYTICS,
      context: 'Get Catering Orders',
      info: {
        locationId,
        startDate,
        endDate,
      },
    },
  });
}

export function getRecentAndUpcomingOrdersFromApi(
  locationId: string,
  apiKey: string,
  numberOfPreviousDays: number,
) {
  const now = constructNow(new Date());
  let startDate = format(subDays(now, numberOfPreviousDays), ooeConstants.DATE_TIME_FORMAT.date);
  const endDate = format(addDays(now, 500), ooeConstants.DATE_TIME_FORMAT.date);
  if (isSunday(startDate)) {
    startDate = format(subDays(now, 1), ooeConstants.DATE_TIME_FORMAT.date);
  }
  return getOrdersFromApi(locationId, apiKey, startDate, endDate);
}

export function getAdditionalPastOrdersFromApi(
  locationId: string,
  apiKey: string,
  startDate: string,
  endDate: string,
) {
  return getOrdersFromApi(locationId, apiKey, startDate, endDate);
}

export function cancelOrderAdminApi(orderId: string, locationNumber: string, cfaId: string, apiKey: string) {
  const baseUrl = ooeConstants.URL.ORDERS;
  const url = `${baseUrl}/reports/1.0/location/${locationNumber}/${orderId}/${cfaId}`;
  return requestFactory({
    url,
    method: 'DELETE',
    auth: {
      type: 'JWTBearer',
      apiKey,
    },
    bugsnag: {
      errorClass: ooeConstants.BUGSNAG_ERRORCLASS_ORDERS,
      context: 'Cancel Order Admin',
      info: {
        orderId,
        locationNumber,
        cfaId,
      },
    },
  });
}

export function lookupPOSOrderDetails(orderId: string, locationNumber: string, apiKey: string) {
  const baseUrl = ooeConstants.URL.ANALYTICS;
  const url = `${baseUrl}/catering/2.0/${locationNumber}/detail?transId=${orderId}`;
  return requestFactory({
    url,
    method: 'GET',
    auth: {
      type: 'JWTBearer',
      apiKey,
    },
    bugsnag: {
      errorClass: ooeConstants.BUGSNAG_ERRORCLASS_ANALYTICS,
      context: 'Lookup POS Order Details',
      info: {
        orderId,
        locationNumber,
      },
    },
    mapper: mapPOSOrderDetail,
  });
}

export function lookupOrderDetailsFromApi(
  orderId: string,
  locationNumber: string,
  cfaId: string,
  apiKey: string,
) {
  const baseUrl = ooeConstants.URL.ORDERS;
  const url = `${baseUrl}/reports/1.0/location/${locationNumber}/${orderId}/${cfaId}`;
  return requestFactory({
    url,
    method: 'GET',
    auth: {
      type: 'JWTBearer',
      apiKey,
    },
    bugsnag: {
      errorClass: ooeConstants.BUGSNAG_ERRORCLASS_ORDERS,
      context: 'Lookup Order Details From API',
      info: {
        orderId,
        locationNumber,
        cfaId,
      },
    },
    mapper: mapDetailViewOrder,
  });
}

export function lookupCustomerDetailsFromCustomerID(
  orderId: string,
  locationNumber: string,
  cfaId: string,
  apiKey: string,
) {
  const baseUrl = ooeConstants.URL.D2C;
  const url = `${baseUrl}/cfa.d2c.customer.v1.CustomerService/GetCustomerByID`;
  const mapper = (customer: Customer) => mapCustomerDetails(customer, orderId);
  return requestFactory({
    url,
    method: 'POST',
    auth: {
      type: 'JWTBearer',
      apiKey,
    },
    body: {
      customerId: cfaId,
      excludeMembershipDetails: false,
    },
    bugsnag: {
      errorClass: ooeConstants.BUGSNAG_ERRORCLASS_D2C,
      context: 'Lookup Customer Details From Customer ID',
      info: {
        orderId,
        locationNumber,
        cfaId,
      },
    },
    mapper,
  });
}

export function resendPaymentEmail(apiKey: string, orderId: string, locationNumber: string, cfaId: string) {
  const baseUrl = ooeConstants.URL.LOCATION_ORDERS;
  const url = `${baseUrl}/${locationNumber}/users/${cfaId}/orders/${orderId}/paymentRequest`;
  return requestFactory({
    url,
    method: 'POST',
    auth: {
      type: 'JWTBearer',
      apiKey,
    },
    bugsnag: {
      errorClass: ooeConstants.BUGSNAG_ERRORCLASS_ORDERS,
      context: 'Resend Payment Email',
      info: {
        orderId,
        locationNumber,
        cfaId,
      },
    },
  });
}

export default {
  getRecentAndUpcomingOrdersFromApi,
  cancelOrderAdminApi,
  lookupOrderDetailsFromApi,
  lookupCustomerDetailsFromCustomerID,
  resendPaymentEmail,
  lookupPOSOrderDetails,
};
